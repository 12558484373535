import React, { useState, useEffect, useContext } from "react";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";

import { UserContext } from "../UserContext";

// -------------------------------------------------  firebase - 1/2
// import { authentication } from "../firebase-config";
// import {
//   signInWithPopup,
//   GoogleAuthProvider,
//   FacebookAuthProvider,
//   signOut,
//   onAuthStateChanged,
// } from "firebase/auth";

export default function Page_Main({ parrent_DB_userinfo }) {
  // -------------------------------------------------  firebase - 2/2
  // const [firebase_userInfo, setFirebase_userInfo] = useState({});
  // {
  // console.log("firebase_userInfo", firebase_userInfo);
  // }

  // onAuthStateChanged(authentication, (currentUser) => {
  //   setFirebase_userInfo(currentUser);
  // });

  // --------------------------------------- use context
  const { contextValue, setContextValue } = useContext(UserContext);

  useEffect(() => {}, []);

  return (
    <>
      <COM_NavBar_Global />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      main
    </>
  );
}
