import react, { useEffect, useState, useRef, useContext } from "react";
import moment from "moment";
import axios from "axios";

import { UserContext } from "../UserContext";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import { FcGoogle as GoogleIcon } from "react-icons/fc";
import { FaFacebookSquare as FacebookIcon } from "react-icons/fa";
// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_Login({ parent_fakeAuth, parrent_DB_userinfo }) {
  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});
  const [firebase_user_SignedIn, setFirebase_user_SignedIn] = useState(false);
  const [DB_user_Authenticated, setDB_user_Authenticated] = useState(false);

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);

    if (authentication?.currentUser != null) {
      setFirebase_user_SignedIn(true);
      // console.log("# user Signed in", firebase_user_SignedIn.current);
    } else {
      setFirebase_user_SignedIn(false);
      // console.log("# user Signed in", firebase_user_SignedIn.current);
    }
  });

  const singInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  // ------------------------------------------------- DB

  // get user info by uid
  const [DB_users, setDB_users] = useState([]);
  const get_DB_users_List = async () => {
    await axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__userinfo_byuuid`,
        {
          u_uid: firebase_userInfo.uid,
        }
      )
      .then((res) => {
        setDB_users(res.data[0]);
        // console.log("# get_DB_users_List", res.data[0]);
        contextValue.bd_UserInfo = res.data[0];

        if (res.data[0] != undefined) {
          // user found in bd
          if (
            res.data[0].u_role === "admin" ||
            res.data[0].u_role === "staff"
          ) {
            // user is Authenticated
            parent_fakeAuth.isAuthenticated = true;
            parrent_DB_userinfo.currentDBUser = res.data[0];

            setDB_user_Authenticated(true);
            // console.log("# window.location", window.location);
            if (
              window.location.toString().split("/#/")[1] == "" &&
              parent_fakeAuth.isAuthenticated
            ) {
              set_DB_Update_Auth_usersInfo();
              window.location.href = "#/ticket"; //def = ticket
            }
          } else {
          }
        } else {
          // add un-Auth User to db
          add_DB_unAuth_users();
          set_DB_Update_Auth_usersInfo();
        }
      });
  };

  // add un-auth user to db
  const add_DB_unAuth_users = async () => {
    await axios
      .post(`${REACT_APP_ECLIPSE_API}/add_eclipse_awesomelist__userinfo`, {
        u_uid: firebase_userInfo.uid,
        u_name: firebase_userInfo.displayName,
        u_email: firebase_userInfo.email,
        u_firebase_obj: JSON.stringify(firebase_userInfo),
      })
      .then((res) => {
        // console.log("# add_DB_unAuth_users", res.data[0]);
      });
  };

  // reupdate auth user info to db
  const set_DB_Update_Auth_usersInfo = async () => {
    await axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_awesomelist__updateAuthUserInfo`,
        {
          u_uid: firebase_userInfo.uid,
          u_name: firebase_userInfo.displayName,
          u_email: firebase_userInfo.email,
          u_firebase_obj: JSON.stringify(firebase_userInfo),
        }
      )
      .then((res) => {
        // console.log("# set_DB_Update_Auth_usersInfo", res.data[0]);
      });
  };

  // get user info by uid
  const [DB_outlet_all, setDB_outlet_all] = useState([]);
  const get_DB_outlet_all_List = async () => {
    const x = await axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__outlet_byoutlet_shortname`
      )
      .then((res) => {
        setDB_outlet_all(res.data);
        // console.log("# get_DB_outlet_all_List", res.data);
        parrent_DB_userinfo.currentDBOutletListAll = res.data;
      });
  };

  // if user Signed in Firebase Auth
  useEffect(() => {
    if (firebase_user_SignedIn) {
      // console.log("# firebase_user_SignedIn", firebase_user_SignedIn);

      // auth with DB users
      get_DB_users_List();

      get_DB_outlet_all_List();
      // console.log("# firebase_userInfo", firebase_userInfo);
      contextValue.firebase_UserInfo = firebase_userInfo;
    }
  }, [firebase_user_SignedIn]);

  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  return (
    <>
      <div className="container_centerpage">
        <>
          <h1>AWESOMELIST</h1>
        </>
        {"firebase_user_SignedIn : " + firebase_user_SignedIn}
        <br />
        {"DB_user_Authenticated : " + DB_user_Authenticated}
        <br />
        {!firebase_user_SignedIn ? (
          <>
            <Button
              onClick={() => {
                singInWithGoogle();
              }}
            >
              <GoogleIcon
                style={{ padding: "0px 10px 0px 0px", fontSize: "30px" }}
              />
              <div>Login with Google</div>
            </Button>

            <Button
              onClick={() => {
                signInWithFacebook();
              }}
            >
              <FacebookIcon
                style={{
                  color: "#4064AC",
                  padding: "0px 10px 0px 0px",
                  fontSize: "30px",
                }}
              />
              <div>Login with Facebook</div>
            </Button>
          </>
        ) : (
          <>
            <div>
              <img src={`${firebase_userInfo?.photoURL}`}></img>
              <div>{`${firebase_userInfo?.displayName}`}</div>
              <div>{`${firebase_userInfo?.email}`}</div>
            </div>

            <Button
              onClick={() => {
                signOut(authentication);
              }}
            >
              LOGOUT
            </Button>
          </>
        )}
      </div>
    </>
  );
}
