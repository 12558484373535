import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import COM_FileUpload from "../Component/COM_FileUpload";
import COM_ChatRoom_Lobby from "../Component/COM_ChatRoom_Lobby";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";

//  -------------------------- mantine
import {
  RadioGroup,
  Radio,
  Autocomplete,
  TextInput,
  Button,
  Modal,
  Group,
  Checkbox,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Refresh, FileSearch, Check, ArrowBigRight } from "tabler-icons-react";
import { NotificationsProvider } from "@mantine/notifications";
import { showNotification, updateNotification } from "@mantine/notifications";

//  -------------------------- Material Table
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import { UserContext } from "../UserContext";
import { Zoom } from "@mui/material";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // ------------------------------------------------- DB

  // all ticket record - pending and solving
  const [
    DB_ticket_log_all_PendingSolving,
    setDB_ticket_log_all_PendingSolving,
  ] = useState([]);
  const get_DB_ticket_log_all_PendingSolving = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__ticket_log__InfoAll_Pending_Solving`,
        {
          cafes: contextValue?.bd_UserInfo?.u_outlets,
        }
      )
      .then((res) => {
        setDB_ticket_log_all_PendingSolving(res.data);
        // console.log("# get_DB_ticket_log_all_PendingSolving", res.data);
      });
  };

  // all ticket record - solved
  const [DB_ticket_log_all_Solved, setDB_ticket_log_all_Solved] = useState([]);
  const get_DB_ticket_log_all_Solved = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__ticket_log__InfoAll_Solved`
      )
      .then((res) => {
        setDB_ticket_log_all_Solved(res.data);
        // console.log("# get_DB_ticket_log_all_Solved", res.data);
      });
  };

  // all ticket record by id
  const [DB_ticket_log_all_byid, setDB_ticket_log_all_byid] = useState([]);
  const get_DB_ticket_log_all_byid = (_t_id) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__ticket_log__InfoAll_byid`,
        {
          t_id: _t_id,
        }
      )
      .then((res) => {
        setDB_ticket_log_all_byid(res.data);
        // console.log("# get_DB_ticket_log_all_byid", res.data);
      });
  };

  // update Ticket Status
  const set_DB_ticket_log__T_Status__byid = (_t_status, _selectedDataObj) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/add_eclipse_awesomelist__ticket_log__statusremark_new`,
        {
          t_id: _selectedDataObj.t_id,

          t_outlet: _selectedDataObj.t_outlet,
          t_category: _selectedDataObj.t_category,
          t_option: _selectedDataObj.t_option,
          t_option2: _selectedDataObj.t_option2,
          t_remark: _selectedDataObj.t_remark,
          t_uuid: _selectedDataObj.t_uuid,

          gen_by: _selectedDataObj.gen_by,
          gen_by_uuid: _selectedDataObj.gen_by_uuid,
          gen_by_pic: _selectedDataObj.gen_by_pic,
          gen_dt: _selectedDataObj.gen_dt,

          t_status: _t_status,
          t_status_remark: _t_status,
          status_by: contextValue?.firebase_UserInfo?.displayName,
          status_by_uuid: contextValue?.firebase_UserInfo?.uid,
          status_by_pic: contextValue?.firebase_UserInfo?.photoURL,

          file_att_url: "",
        }
      )
      .then((res) => {
        if (res.data.affectedRows > 0) {
          get_DB_ticket_log_all_PendingSolving();
          get_DB_ticket_log_all_Solved();

          // notification
          if (_t_status == "pending") {
            showNoti("pending", _selectedDataObj.t_id);
          }
          if (_t_status == "troubleshooting") {
            showNoti("troubleshooting", _selectedDataObj.t_id);
          }
          if (_t_status == "solved") {
            showNoti("solved", _selectedDataObj.t_id);
          }
        } else {
          alert("FAILED, pls contact admin");
        }
        // console.log("# set_DB_ticket_log__T_Status__byid", res.data);
      });
  };

  // ------------------------------------------------- Table Columns

  const columns = [
    // {
    //   title: "Ticket Id",
    //   field: "t_id",
    //   width: "1%",
    //   cellStyle: {
    //     backgroundColor: "#2bc3ff",
    //     color: "#000",
    //     fontSize: "2rem",
    //   },
    //   headerStyle: {
    //     backgroundColor: "#2bc3ff",
    //     color: "#000",
    //     maxWidth: "1px",
    //   },
    // },
    {
      title: "Ticket ID / ACTION",
      field: "t_id",
      width: "1px",
      cellStyle: {
        backgroundColor: "#2bc3ff",
        color: "#000",
        fontSize: "2rem",
      },
      headerStyle: {
        backgroundColor: "#2bc3ff",
        color: "#000",
        // maxWidth: "1px",
      },
      render: (rowData) => (
        <>
          <div
            className="btn_TableEdit"
            onClick={(e) => {
              setSelected_RowData(rowData);
              get_DB_ticket_log_all_byid(rowData.t_id);
              setDialogOpened(true);
            }}
          >
            <div className="Action_profil_TicketNo ">
              <div>{`${rowData.t_id}`}</div>
            </div>

            <div className="container_center">
              <img className="Action_profilPic" src={rowData.gen_by_pic} />
            </div>
            <div className="Action_profil_Name">{rowData.gen_by}</div>
          </div>
        </>
      ),
    },

    // {
    //   title: "Created by",
    //   render: (rowData) => (
    //     <div className="container_center">
    //       <img className="ico_profilPic" src={rowData.gen_by_pic} />
    //       {rowData.gen_by}
    //     </div>
    //   ),
    // },

    {
      title: "Status",
      field: "t_status",
      width: "1px",
      cellStyle: {
        // maxWidth: "1rem",
      },
      render: (rowData) => (
        <>
          <div className="container_center gap_dot2rem">
            <div
              className={
                rowData.t_status == "pending" ? "btn_Pending" : "btn_NULL"
              }
              onClick={() => {
                set_DB_ticket_log__T_Status__byid("pending", rowData);
              }}
            >
              <div className="container_center">
                <div className="glo_FontSize_1rem">PENDING</div>
                {rowData.t_status == "pending" ? (
                  <>
                    {/* <div className="text_Aging ">{`updated : ${rowData.aging_update}`}</div> */}
                    <div className="dis_container_inStatusbtn text_ColorBlack">
                      {/* <div className="flexRow">
                        <img
                          className="ico_profilPic_smallest"
                          src={rowData.status_by_pic}
                        />

                        <div className="container_center">
                          <div className=" ">{`${rowData.status_by}:`}</div>
                          <div className="glo_FontSize_1rem ">{`  ${rowData.t_status_remark}`}</div>
                        </div>
                      </div> */}
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </div>

            {/* <div
              className={
                rowData.t_status == "troubleshooting"
                  ? "btn_troubleshooting"
                  : "btn_NULL"
              }
              onClick={() => {
                set_DB_ticket_log__T_Status__byid("troubleshooting", rowData);
              }}
            >
              <div className="container_center">
                <div className="glo_FontSize_1rem">TROUBLESHOOTING</div>
                {rowData.t_status == "troubleshooting" ? (
                  <>
                    <div className="text_Aging ">{`updated : ${rowData.aging_update}`}</div>
                    <div className="dis_container_inStatusbtn text_ColorBlack">
                      <div className="flexRow">
                        <img
                          className="ico_profilPic_smallest"
                          src={rowData.status_by_pic}
                        />

                        <div className="container_center">
                          <div className=" ">{`${rowData.status_by}:`}</div>
                          <div className="glo_FontSize_1rem ">{`  ${rowData.t_status_remark}`}</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </div> */}

            <div
              className={
                rowData.t_status == "solved" ? "btn_solved" : "btn_NULL"
              }
              onClick={() => {
                if (rowData.t_category == "DailySubmission") {
                  if (contextValue?.bd_UserInfo?.u_role == "admin") {
                    set_DB_ticket_log__T_Status__byid("solved", rowData);
                  } else {
                    alert("Staff , Not Allow");
                  }
                } else {
                  set_DB_ticket_log__T_Status__byid("solved", rowData);
                }
              }}
            >
              <div className="container_center">
                <div className="glo_FontSize_1rem">SOLVED</div>
                {rowData.t_status == "solved" ? (
                  <>
                    {/* <div className="text_Aging ">{`updated : ${rowData.aging_update}`}</div> */}
                    <div className="dis_container_inStatusbtn text_ColorBlack">
                      {/* <div className="flexRow">
                        <img
                          className="ico_profilPic_smallest"
                          src={rowData.status_by_pic}
                        />

                        <div className="container_center">
                          <div className=" ">{`${rowData.status_by}:`}</div>
                          <div className="glo_FontSize_1rem ">{`  ${rowData.t_status_remark}`}</div>
                        </div>
                      </div> */}
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          {/* {rowData.t_status == "pending" ? (
            <div className="text_Pending">PENDING</div>
          ) : rowData.t_status == "troubleshooting" ? (
            <div className="text_Troubleshooting">TROUBLESHOOTING</div>
          ) : rowData.t_status == "solved" ? (
            <div className="text_Solved">SOLVED</div>
          ) : (
            <div>NA</div>
          )} */}
        </>
      ),
    },
    {
      title: "Aging / Create Date",
      field: "gen_dt",
      width: "1px",
      minWidth: "120px",
      render: (rowData) => (
        <>
          <div
            className={`${
              rowData.aging_create.split(",")[0] != "0day"
                ? "text_Aging_Remain_Day"
                : ""
            }`}
          >{`${
            rowData.aging_create.split(",")[0] != "0day"
              ? rowData.aging_create.split(",")[0]
              : ""
          }`}</div>

          <div
            className={`${
              rowData.aging_create.split(",")[1].trim() != "0h"
                ? "text_Aging_Remain_Hour"
                : ""
            }`}
            // className="text_Aging_Remain_Hour"
          >{` 
           ${
             rowData.aging_create.split(",")[1].trim() != "0h"
               ? rowData.aging_create.split(",")[1]
               : ""
           }`}</div>

          <div className="text_Aging_Remain_Min">{` 
           ${rowData.aging_create.split(",")[2]}`}</div>
          <br />
          <div>{`${rowData.gen_dt} `}</div>
        </>
      ),
    },
    {
      title: "Summary",
      field: "t_outlet",
      width: "1px",

      cellStyle: {
        minWidth: "33rem",
      },
      render: (rowData) => (
        <>
          <div className="text_fontzie_1rem">🎪{rowData.t_outlet}</div>

          <div
            className={`text_fontzie_1rem marginLeft_2rem ${
              rowData.t_category == "DailySubmission" ? "text_Gold" : ""
            }`}
          >
            🏷️{rowData.t_category}
          </div>

          <div className="text_fontzie_1rem marginLeft_4rem">
            🔍{rowData.t_option}
          </div>

          <div className="text_fontzie_1rem marginLeft_4rem">
            🔍
            {rowData.t_option == "FnB"
              ? `🧃${rowData.t_option2?.split(",")[0]}, 🥡 ${
                  rowData.t_option2?.split(",")[1]
                }, 🍜${rowData.t_option2?.split(",")[2]}, 🥛${
                  rowData.t_option2?.split(",")[3]
                }`
              : rowData.t_category == "Internet"
              ? `⏳${rowData.t_option2?.split(",")[0]}ms, ⤴️ ${
                  rowData.t_option2?.split(",")[1]
                } mbps, ⤵️${rowData.t_option2?.split(",")[2]} mbps
                `
              : rowData.t_option2}
          </div>

          <div className="text_fontzie_1rem marginLeft_4rem">
            📌{rowData.t_remark}
          </div>
        </>
      ),
    },
    {
      title: "Outlet",
      field: "t_outlet",
      width: "1px",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_outlet}</div>
      ),
    },
    {
      title: "Category",
      field: "t_category",
      width: "1px",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_category}</div>
      ),
    },
    {
      title: "Option",
      field: "t_option",
      width: "10%",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_option}</div>
      ),
    },
    {
      title: "Option2",
      field: "t_option2",
      width: "10%",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_option2}</div>
      ),
    },
    {
      title: "Remark",
      field: "t_remark",
      width: "50%",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_remark}</div>
      ),
    },

    // {
    //   title: "Status Comment at",
    //   field: "status_dt",
    //   minWidth: "130px",
    // },

    // {
    //   title: "Comment by",
    //   render: (rowData) => (
    //     <div className="container_center">
    //       <img className="ico_profilPic" src={rowData.status_by_pic} />
    //       {rowData.status_by}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Last Comment",
    //   field: "t_status_remark",
    //   minWidth: "120px",
    // },
  ];

  // --------------------------------------- func

  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // --------------------------------------- notification

  const showNoti = (_Status, _t_id) => {
    showNotification({
      title: (
        <>
          <div className="flexRow">
            <div className="notif_TiketID">{_t_id}</div>
            <div className="notif_ArrowPointRight">
              <ArrowBigRight />
            </div>
            {_Status == "pending" ? (
              <div className="notif_Pending">Pending</div>
            ) : (
              ""
            )}
            {_Status == "troubleshooting" ? (
              <div className="notif_Troubleshooting">Troubleshooting</div>
            ) : (
              ""
            )}
            {_Status == "solved" ? (
              <div className="notif_Solved">Solved</div>
            ) : (
              ""
            )}
            {_Status == "data-reloaded" ? (
              <div className="notif_Solved">Data Reloaded</div>
            ) : (
              ""
            )}
          </div>
        </>
      ),
      message: "",
      color: "green",
      icon: <Check />,
      autoClose: 10000,
    });
  };

  // --------------------------------------- dialog / model

  const [dialogOpened, setDialogOpened] = useState(false);

  // --------------------------------------- global variable
  const [selected_RowData, setSelected_RowData] = useState([]);
  const [cb_Show_Solved, setCb_Show_Solved] = useState(false);

  useEffect(() => {
    get_DB_ticket_log_all_PendingSolving();
    get_DB_ticket_log_all_Solved();
    // console.log("Page_createTicket", contextValue);
  }, []);

  return (
    <>
      <COM_NavBar_Global />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>My Tickets</h1>
      <div className="pageBody_Container">
        <div className="pageBody_Container2">
          <div>Tips 1 : MyTicket all SOLVED = Good JOB! </div>
          <div className="">
            Tips 2 : Supervisor / Admin need to help Click SOLVED for
            DailySubmission{" "}
          </div>
          <br />
          <div className="flexRow ">
            You have{" "}
            <div className="notif_Pending margin_1rem_leftright">
              {cb_Show_Solved == true
                ? DB_ticket_log_all_Solved?.length
                : DB_ticket_log_all_PendingSolving?.length}
            </div>
            Panding Task Now
          </div>
          <br />
          <MaterialTable
            style={{
              MuiTablePaginationRoot: "gold",
              color: "white",
              // background: "rgba(0, 0, 100, .0)",
              backgroundImage:
                "linear-gradient(to bottom right, #ffd900, #ffa90a)",
              boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
              backdropFilter: "blur( 11.0px )",
              "&::webkit-backdrop-filter": {
                webkitBackdropFilter: "blur( 18.0px )",
              },
            }}
            title={
              <div className="flexRow">
                <div className="text_TableTitle margin_1rem_leftright">
                  {`My Ticket (${
                    cb_Show_Solved == true
                      ? DB_ticket_log_all_Solved?.length
                      : DB_ticket_log_all_PendingSolving?.length
                  })`}
                </div>
                <div
                  className="margin_1rem_leftright btn_RB_Type2"
                  size="xl"
                  // variant="subtle"
                  color="grape"
                  onClick={() => {
                    get_DB_ticket_log_all_PendingSolving();
                    get_DB_ticket_log_all_Solved();

                    showNoti("data-reloaded", "s");
                  }}
                >
                  <Refresh />
                  Reload Data
                </div>
                <Checkbox
                  className="margin_1rem_leftright"
                  // style={{ marginTop: "-4rem" }}
                  size="xl"
                  label={
                    <div className="btn_RB_Type3">Show Solved Tickets</div>
                  }
                  checked={cb_Show_Solved}
                  onChange={(event) =>
                    setCb_Show_Solved(event.currentTarget.checked)
                  }
                />
              </div>
            }
            columns={columns}
            data={
              cb_Show_Solved == true
                ? DB_ticket_log_all_Solved
                : DB_ticket_log_all_PendingSolving
            }
            options={{
              headerStyle: {
                background: "rgba(255, 255, 255, .2)",
                border: "none",
              },
              exportButton: true,
              rowStyle: (rowData) => ({
                backgroundColor: "#1a0000",
                color: "#ffffff",
                fontSize: 12,
              }),
              filtering: true,
              // grouping: true,
              // exportAllData: true,
              pageSize: 20,
              pageSizeOptions: [100, 200, , 500, 1000],
              paginationType: "stepped",
              // actionsColumnIndex: -1
              // fixedColumns: {
              //     left: 1,
              // }
              // exportMenu: [
              //   {
              //     label: "Export PDF",
              //     exportFunc: (cols, datas) =>
              //       ExportPdf(cols, datas, "myPdfFileName"),
              //   },
              //   {
              //     label: "Export CSV",
              //     exportFunc: (cols, datas) =>
              //       ExportCsv(cols, datas, "myCsvFileName"),
              //   },
              // ],
            }}
            // onRowClick={(evt, selectedRow) => {
            // alert(selectedRow.id);
            // }}
            // actions={[
            //   {
            //     icon: "save",
            //     tooltip: "Save User",
            //     onClick: (event, rowData) => {
            //       // handleClickOpen_1_2();
            //       // getDB_patroninfo_byid(rowData.id);
            //       // alert(rowData.id);
            //     },
            //   },
            // ]}
            components={{
              Action: (props) => (
                <>
                  {/* <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "gold" }}>DONE</div>
              </Button>
              <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "#2bc3ff" }}>EDIT</div>
              </Button> */}
                </>
              ),
            }}
          />

          <Modal
            opened={dialogOpened}
            onClose={() => setDialogOpened(false)}
            title={
              <>{`My Ticket Details : Ticket ID = ${selected_RowData.t_id}`}</>
            }
            centered
            size="full"
            transition="slide-down"
            transitionDuration={600}
            transitionTimingFunction="ease"
            overflow="inside"
          >
            {/* <div className="flexRow gap_10">
              <div className="text_outlet">{selected_RowData.t_outlet}</div>
              <div className="text_category">{selected_RowData.t_category}</div>
              <div className="text_option">{selected_RowData.t_option}</div>
              <div className="text_option2">{selected_RowData.t_option2}</div>
              <div className="text_remark">{selected_RowData.t_remark}</div>
            </div>
            <br /> */}
            {/* <div className="flexRow gap_10">
              <div>CHOOSE STATUS : </div>
              <div
                className={
                  selected_RowData.t_status == "pending"
                    ? "btn_Pending"
                    : "btn_NULL"
                }
                onClick={() => {
                  set_DB_ticket_log__T_Status__byid(
                    "pending",
                    selected_RowData
                  );
                }}
              >
                Set PENDING
              </div>
              <div
                className={
                  selected_RowData.t_status == "troubleshooting"
                    ? "btn_Troubleshooting"
                    : "btn_NULL"
                }
                onClick={() => {
                  set_DB_ticket_log__T_Status__byid(
                    "troubleshooting",
                    selected_RowData
                  );
                }}
              >
                Set TROUBLESHOOTING
              </div>
              <div
                className={
                  selected_RowData.t_status == "solved"
                    ? "btn_Solved"
                    : "btn_NULL"
                }
                onClick={() => {
                  set_DB_ticket_log__T_Status__byid("solved", selected_RowData);
                }}
              >
                Set SOLVED
              </div>
            </div> */}
            {/* {DB_ticket_log_all_byid?.map((x) => (
            <>
              <div className="dis_container">
                <div className="flexRow_start">
                  <img className="dis_profilPic" src={x.status_by_pic} />
                  <b>{x.status_by}</b>
                  <div className="dis_date">{x.status_dt}</div>
                </div>
                <div className="dis_comment">{x.t_status_remark}</div>
              </div>
            </>
          ))} */}
            <br />
            <br />
            <COM_ChatRoom_Lobby
              p_roomID={"AweSoMe_" + selected_RowData.t_id}
              p_userInfo={{
                uid: contextValue?.firebase_UserInfo?.uid,
                u_name: contextValue?.firebase_UserInfo?.displayName,
                u_pic_url: contextValue?.firebase_UserInfo?.photoURL,
              }}
              p_RowData={selected_RowData}
            />
          </Modal>
        </div>
      </div>
    </>
  );
}
