import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";

import { showNotification, updateNotification } from "@mantine/notifications";
import { Refresh, FileSearch, Check, ArrowBigRight } from "tabler-icons-react";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // get version
  const getVersion = () => {
    axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__version`)
      .then((res) => {
        // console.log("ss1", res);

        // setCurrentVersion(res.data[0].value);

        if (localStorage.getItem("showVerNotif") == "true") {
          showNoti("New Version Updated", res.data[0].value);
          localStorage.setItem("showVerNotif", false);
        }

        if (localStorage.getItem("ver") === null) {
          localStorage.setItem("ver", res.data[0].value);
          localStorage.setItem("showVerNotif", "true");

          window.location.reload();
        }
        if (localStorage.getItem("ver") !== res.data[0].value) {
          localStorage.setItem("ver", res.data[0].value);
          localStorage.setItem("showVerNotif", "true");

          window.location.reload();
        }
      });
  };

  // --------------------------------------- notification

  const showNoti = (_title, _message) => {
    showNotification({
      title: (
        <>
          <div className="flexRow">{_title}</div>
        </>
      ),
      message: (
        <>
          <div className="flexRow">{_message}</div>
        </>
      ),

      color: "green",
      icon: <Check />,
      autoClose: 600 * 1000,
      // disallowClose: true,
    });
  };

  // const [currentVersion, setCurrentVersion] = useState("ver: ?");

  useEffect(() => {
    getVersion();
  }, []);

  return <div> </div>;
}
