import React, { useState } from "react";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_UserProfile({ parrent_DB_userinfo }) {
  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  return (
    <>
      <Button
        className="button-Logout"
        onClick={() => {
          signOut(authentication);
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        }}
      >
        <img
          className="smallcontainer2-img"
          src={`${firebase_userInfo?.photoURL}`}
        ></img>
        <div className="smallcontainer2">
          <div>{`${firebase_userInfo?.displayName}`}</div>
          <div>{`${parrent_DB_userinfo?.currentDBUser?.u_role}`}</div>
          <div className="button-Logout--text"> LOGOUT</div>
        </div>
      </Button>
    </>
  );
}
