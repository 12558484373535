import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import COM_FileUpload from "../Component/COM_FileUpload";
import COM_ChatRoom_Lobby from "../Component/COM_ChatRoom_Lobby";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";

//  -------------------------- mantine
import {
  RadioGroup,
  Radio,
  Autocomplete,
  TextInput,
  Button,
  Modal,
  Group,
  Checkbox,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Refresh, FileSearch, Check, ArrowBigRight } from "tabler-icons-react";
import { NotificationsProvider } from "@mantine/notifications";
import { showNotification, updateNotification } from "@mantine/notifications";

//  -------------------------- Material Table
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import { UserContext } from "../UserContext";
import { Zoom } from "@mui/material";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // ------------------------------------------------- DB

  // all ticket record - pending and solving

  // const get_DB_ticket_log_all__Ready1 = () => {
  //   axios
  //     .post(
  //       `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__requestticket_log__InfoAll_Pending_Solving`,
  //       {
  //         cafes: contextValue?.bd_UserInfo?.u_outlets,
  //       }
  //     )
  //     .then((res) => {
  //       setDB(res.data);
  //       console.log("# get_DB_ticket_log_all_PendingSolving", res.data);
  //     });
  // };

  // // all ticket record - solved
  // const [DB_ticket_log_all_Solved, setDB_ticket_log_all_Solved] = useState([]);
  // const get_DB_ticket_log_all_Solved = () => {
  //   axios
  //     .post(
  //       `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__requestticket_log__InfoAll_Solved`
  //     )
  //     .then((res) => {
  //       setDB_ticket_log_all_Solved(res.data);
  //       // console.log("# get_DB_ticket_log_all_Solved", res.data);
  //     });
  // };

  // // all ticket record by id
  // const [DB_ticket_log_all_byid, setDB_ticket_log_all_byid] = useState([]);
  // const get_DB_ticket_log_all_byid = (_t_id) => {
  //   axios
  //     .post(
  //       `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__ticket_log__InfoAll_byid`,
  //       {
  //         t_id: _t_id,
  //       }
  //     )
  //     .then((res) => {
  //       setDB_ticket_log_all_byid(res.data);
  //       // console.log("# get_DB_ticket_log_all_byid", res.data);
  //     });
  // };

  // update Ticket Status
  const set_DB_ticket_log__T_Status__byid = (_t_status, _selectedDataObj) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/add_eclipse_awesomelist__ticket_log__statusremark_new`,
        {
          t_id: _selectedDataObj.t_id,

          t_outlet: _selectedDataObj.t_outlet,
          t_category: _selectedDataObj.t_category,
          t_option: _selectedDataObj.t_option,
          t_option2: _selectedDataObj.t_option2,
          t_remark: _selectedDataObj.t_remark,
          t_uuid: _selectedDataObj.t_uuid,

          gen_by: _selectedDataObj.gen_by,
          gen_by_uuid: _selectedDataObj.gen_by_uuid,
          gen_by_pic: _selectedDataObj.gen_by_pic,
          gen_dt: _selectedDataObj.gen_dt,

          t_status: _t_status,
          t_status_remark: _t_status,
          status_by: contextValue?.firebase_UserInfo?.displayName,
          status_by_uuid: contextValue?.firebase_UserInfo?.uid,
          status_by_pic: contextValue?.firebase_UserInfo?.photoURL,

          file_att_url: "",
        }
      )
      .then((res) => {
        if (res.data.affectedRows > 0) {
          // get_DB_ticket_log_all__Ready1();
          // get_DB_ticket_log_all_Solved();

          // notification
          if (_t_status == "pending") {
            showNoti("pending", _selectedDataObj.t_id);
          }
          if (_t_status == "troubleshooting") {
            showNoti("troubleshooting", _selectedDataObj.t_id);
          }
          if (_t_status == "solved") {
            showNoti("solved", _selectedDataObj.t_id);
          }
        } else {
          alert("FAILED, pls contact admin");
        }
        // console.log("# set_DB_ticket_log__T_Status__byid", res.data);
      });
  };

  // get ready1 data
  const [DB_Ready1, setDB_Ready1] = useState([]);
  const get_DB_ticket_log__ready1 = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__requestticket_log__ready1_all`,
        {}
      )
      .then((res) => {
        // console.log("# get_DB_ticket_log__ready1", res.data);

        setDB_Ready1(res.data);
      });
  };

  // get ready2 data
  const [DB_Ready2, setDB_Ready2] = useState([]);
  const get_DB_ticket_log__ready2 = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__requestticket_log__ready2_all`,
        {}
      )
      .then((res) => {
        // console.log("# get_DB_ticket_log__ready2", res.data);
        setDB_Ready2(res.data);
      });
  };

  // get ready3 data
  const [DB_Ready3, setDB_Ready3] = useState([]);
  const get_DB_ticket_log__ready3 = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__requestticket_log__ready3_all`,
        {}
      )
      .then((res) => {
        // console.log("# get_DB_ticket_log__ready3", res.data);
        setDB_Ready3(res.data);
      });
  };
  // get ready4 data
  const [DB_Ready4, setDB_Ready4] = useState([]);
  const get_DB_ticket_log__ready4 = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__requestticket_log__ready4_all`,
        {}
      )
      .then((res) => {
        // console.log("# get_DB_ticket_log__ready4", res.data);
        setDB_Ready4(res.data);
      });
  };
  // get archive data
  const [DB_Archive, setDB_Archive] = useState([]);
  const get_DB_ticket_log__Archive = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__ticket_log__archive_all`,
        {}
      )
      .then((res) => {
        // console.log(
        //   "# get_eclipse_awesomelist__ticket_log__archive_all",
        //   res.data
        // );
        setDB_Archive(res.data);
      });
  };

  // ready1 -> ready2
  const set_DB_ticket_log__ready1_to_ready2 = (
    ready1_notes,
    ready1_by,
    t_id,
    rowData
  ) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_awesomelist__ticket_log__ready1_to_ready2`,
        {
          ready1_notes,
          ready1_by,
          t_id,
        }
      )
      .then((res) => {
        // console.log(
        //   "# set_eclipse_awesomelist__ticket_log__ready1_to_ready2",
        //   res.data
        // );
        Reload_DB();
        if (res.data?.affectedRows > 0) {
          // alert("done");

          // ------------------------ send telegram "My Request Ticket"
          let RequestTicket_String =
            "🈴 APPROVED " +
            "👤 by : " +
            ready1_by +
            "\n" +
            "🔔 REQUEST Ticket : " +
            rowData?.t_id +
            "\n\n" +
            "🎪 Outlet : " +
            rowData?.t_outlet +
            "\n" +
            "🏷️ Category : " +
            rowData?.t_category +
            "\n\n" +
            "📦 Item : " +
            rowData?.t_option +
            "\n" +
            "🧮 Quantity : " +
            rowData?.t_option2.toLowerCase().trim() +
            "\n\n" +
            "📌 Remark : " +
            ready1_notes +
            "\n\n";

          let toWho = [
            "1093252356", // shwan
            "1143431696", // chun
            "5097555194", // skeith
            "1629617723", // william
          ];
          Send_Telegram_Notification(toWho, RequestTicket_String);
        }
      });
  };

  // ready2 -> ready1
  const set_DB_ticket_log__ready2_to_ready1 = (ready1_by, t_id) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_awesomelist__ticket_log__ready2_to_ready1`,
        {
          ready1_by,
          t_id,
        }
      )
      .then((res) => {
        // console.log(
        //   "# set_eclipse_awesomelist__ticket_log__ready2_to_ready1",
        //   res.data
        // );
        Reload_DB();
      });
  };

  // ready2 -> ready3
  const set_DB_ticket_log__ready2_to_ready3 = (
    ready2_notes,
    ready2_by,
    t_id
  ) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_awesomelist__ticket_log__ready2_to_ready3`,
        {
          ready2_notes,
          ready2_by,
          t_id,
        }
      )
      .then((res) => {
        // console.log(
        //   "# set_eclipse_awesomelist__ticket_log__ready2_to_ready3",
        //   res.data
        // );
        Reload_DB();
      });
  };

  // ready3 -> ready2
  const set_DB_ticket_log__ready3_to_ready2 = (ready2_by, t_id) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_awesomelist__ticket_log__ready3_to_ready2`,
        {
          ready2_by,
          t_id,
        }
      )
      .then((res) => {
        // console.log(
        //   "# set_eclipse_awesomelist__ticket_log__ready3_to_ready2",
        //   res.data
        // );
        Reload_DB();
      });
  };

  // ready3 -> ready4
  const set_DB_ticket_log__ready3_to_ready4 = (
    ready3_notes,
    ready3_by,
    t_id
  ) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_awesomelist__ticket_log__ready3_to_ready4`,
        {
          ready3_notes,
          ready3_by,
          t_id,
        }
      )
      .then((res) => {
        // console.log(
        //   "# set_eclipse_awesomelist__ticket_log__ready3_to_ready4",
        //   res.data
        // );
        Reload_DB();
      });
  };
  // archive
  const set_DB_ticket_log__archive_bytid = (
    t_id,
    archive_reason,
    archive_by
  ) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_awesomelist__ticket_log__archive_bytid`,
        {
          t_id,
          archive_reason,
          archive_by,
        }
      )
      .then((res) => {
        // console.log(
        //   "# set_eclipse_awesomelist__ticket_log__archive_bytid",
        //   res.data
        // );
        Reload_DB();
      });
  };

  // send Telegram Notification
  const Send_Telegram_Notification = (chat_ids, text) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/add__telegram_api__sendMessage__AweSoMe`,
        {
          chat_ids,
          text,
        }
      )
      .then((data) => {
        // getDB_ListItem();
        // console.log("data", data);
      });
  };

  // ------------------------------------------------- Table Columns

  const columns = [
    // {
    //   title: "Ticket Id",
    //   field: "t_id",
    //   width: "1%",
    //   cellStyle: {
    //     backgroundColor: "#2bc3ff",
    //     color: "#000",
    //     fontSize: "2rem",
    //   },
    //   headerStyle: {
    //     backgroundColor: "#2bc3ff",
    //     color: "#000",
    //     maxWidth: "1px",
    //   },
    // },
    {
      title: "Ticket ID / ACTION",
      field: "t_id",
      width: "1px",
      cellStyle: {
        backgroundColor: "#2bc3ff",
        color: "#000",
        fontSize: "2rem",
      },
      headerStyle: {
        backgroundColor: "#2bc3ff",
        color: "#000",
        // maxWidth: "1px",
      },
      render: (rowData) => (
        <>
          <div
            className="btn_TableEdit"
            onClick={(e) => {
              setSelected_RowData(rowData);
              // get_DB_ticket_log_all_byid(rowData.t_id);
              setDialogOpened(true);
            }}
          >
            <div className="Action_profil_TicketNo ">
              <div>{`${rowData.t_id}`}</div>
            </div>

            <div className="container_center">
              <img className="Action_profilPic" src={rowData.gen_by_pic} />
            </div>
            <div className="Action_profil_Name">{rowData.gen_by}</div>
          </div>
        </>
      ),
    },

    {
      title: "Status",
      field: "t_status",
      width: "1px",

      cellStyle: {
        minWidth: "20rem",
      },
      render: (rowData) => (
        <>
          {readyArchive_isToggled === false ? (
            <TextInput
              // label="Remark"
              required
              placeholder="Remark"
              size="xl"
              // value={selected__Option2a}
              // defaultValue="0"
              onChange={(event) =>
                (tb_Remarks.current = event.currentTarget.value)
              }
            />
          ) : (
            `Reject Reason : ${rowData?.archive_reason}`
          )}

          <br />
          {ready1_isToggled ? (
            <div
              className="btn_solved"
              onClick={() => {
                // set_DB_ticket_log__ready1_to_ready2(
                //   "okok",
                //   contextValue?.firebase_UserInfo?.email,
                //   rowData?.t_id
                // );
                if (window.confirm("Confirm Approve?")) {
                  if (Full_AUTH__UserCheck()) {
                    set_DB_ticket_log__ready1_to_ready2(
                      tb_Remarks.current,
                      contextValue?.firebase_UserInfo?.email,
                      rowData?.t_id,
                      rowData
                    );
                    // console.log("✅APPROVE", rowData);
                    // console.log(
                    //   "✅APPROVE info",
                    //   tb_Remarks.current,
                    //   contextValue?.firebase_UserInfo?.email,
                    //   rowData?.t_id
                    // );
                  } else {
                    alert("Please Find Skeith ZERO");
                  }
                }
              }}
            >
              <div className="glo_FontSize_1rem">✅APPROVE 1→2</div>
            </div>
          ) : ready2_isToggled ? (
            <>
              <div
                className="btn_solved"
                onClick={() => {
                  // set_DB_ticket_log__ready1_to_ready2(
                  //   "okok",
                  //   contextValue?.firebase_UserInfo?.email,
                  //   rowData?.t_id
                  // );
                  if (window.confirm("Confirm Proceed 2→43?")) {
                    if (Full_AUTH__UserCheck() || Admin1_AUTH__UserCheck()) {
                      set_DB_ticket_log__ready2_to_ready3(
                        tb_Remarks.current,
                        contextValue?.firebase_UserInfo?.email,
                        rowData?.t_id
                      );
                      // console.log("✅APPROVE", rowData);
                      // console.log(
                      //   "✅APPROVE info",
                      //   tb_Remarks.current,
                      //   contextValue?.firebase_UserInfo?.email,
                      //   rowData?.t_id
                      // );
                    } else {
                      alert("Please Find William");
                    }
                  }
                }}
              >
                <div className="glo_FontSize_1rem">
                  ✅Ready to Collected 2→3
                </div>
              </div>
              <div
                className="btn_solved"
                onClick={() => {
                  // set_DB_ticket_log__ready1_to_ready2(
                  //   "okok",
                  //   contextValue?.firebase_UserInfo?.email,
                  //   rowData?.t_id
                  // );
                  if (window.confirm("Confirm Proceed 2→4?")) {
                    if (Full_AUTH__UserCheck() || Admin1_AUTH__UserCheck()) {
                      set_DB_ticket_log__ready2_to_ready3(
                        tb_Remarks.current,
                        contextValue?.firebase_UserInfo?.email,
                        rowData?.t_id
                      );
                      set_DB_ticket_log__ready3_to_ready4(
                        tb_Remarks.current,
                        contextValue?.firebase_UserInfo?.email,
                        rowData?.t_id
                      );
                      // console.log("✅APPROVE", rowData);
                      // console.log(
                      //   "✅APPROVE info",
                      //   tb_Remarks.current,
                      //   contextValue?.firebase_UserInfo?.email,
                      //   rowData?.t_id
                      // );
                    } else {
                      alert("Please Find William");
                    }
                  }
                }}
              >
                <div className="glo_FontSize_1rem">
                  ✅Ready to Collected 2→4
                </div>
              </div>
            </>
          ) : ready3_isToggled ? (
            <div
              className="btn_solved"
              onClick={() => {
                // set_DB_ticket_log__ready1_to_ready2(
                //   "okok",
                //   contextValue?.firebase_UserInfo?.email,
                //   rowData?.t_id
                // );
                if (window.confirm("Confirm Proceed 3→4?")) {
                  if (
                    contextValue?.firebase_UserInfo?.email ===
                      "shawnlwy85@gmail.com" ||
                    contextValue?.firebase_UserInfo?.email ===
                      "skeithzero@gmail.com"
                  ) {
                    set_DB_ticket_log__ready3_to_ready4(
                      tb_Remarks.current,
                      contextValue?.firebase_UserInfo?.email,
                      rowData?.t_id
                    );
                    // console.log("✅APPROVE", rowData);
                    // console.log(
                    //   "✅APPROVE info",
                    //   tb_Remarks.current,
                    //   contextValue?.firebase_UserInfo?.email,
                    //   rowData?.t_id
                    // );
                  } else {
                    // alert("Please Find Skeith ZERO");
                  }
                }
              }}
            >
              <div className="glo_FontSize_1rem">
                ✅Spoiled Item Collected 3→4
              </div>
            </div>
          ) : ready4_isToggled ? (
            "DB_Ready4"
          ) : (
            ""
          )}

          {ready1_isToggled ? (
            ""
          ) : ready2_isToggled ? (
            <div
              className="btn_back"
              onClick={() => {
                // set_DB_ticket_log__ready1_to_ready2(
                //   "okok",
                //   contextValue?.firebase_UserInfo?.email,
                //   rowData?.t_id
                // );
                if (
                  contextValue?.firebase_UserInfo?.email ===
                    "shawnlwy85@gmail.com" ||
                  contextValue?.firebase_UserInfo?.email ===
                    "skeithzero@gmail.com"
                ) {
                  set_DB_ticket_log__ready2_to_ready1(
                    contextValue?.firebase_UserInfo?.email,
                    rowData?.t_id
                  );
                  // console.log("✅APPROVE", rowData);
                  // console.log(
                  //   "✅APPROVE info",
                  //   tb_Remarks.current,
                  //   contextValue?.firebase_UserInfo?.email,
                  //   rowData?.t_id
                  // );
                } else {
                  // alert("Please Find Skeith ZERO");
                }
              }}
            >
              <div className="glo_FontSize_1rem">1⬅️2 Back</div>
            </div>
          ) : ready3_isToggled ? (
            <div
              className="btn_back"
              onClick={() => {
                // set_DB_ticket_log__ready1_to_ready2(
                //   "okok",
                //   contextValue?.firebase_UserInfo?.email,
                //   rowData?.t_id
                // );
                if (
                  contextValue?.firebase_UserInfo?.email ===
                    "shawnlwy85@gmail.com" ||
                  contextValue?.firebase_UserInfo?.email ===
                    "skeithzero@gmail.com"
                ) {
                  set_DB_ticket_log__ready3_to_ready2(
                    contextValue?.firebase_UserInfo?.email,
                    rowData?.t_id
                  );
                  // console.log("✅APPROVE", rowData);
                  // console.log(
                  //   "✅APPROVE info",
                  //   tb_Remarks.current,
                  //   contextValue?.firebase_UserInfo?.email,
                  //   rowData?.t_id
                  // );
                } else {
                  // alert("Please Find Skeith ZERO");
                }
              }}
            >
              <div className="glo_FontSize_1rem">2⬅️3 Back</div>
            </div>
          ) : ready4_isToggled ? (
            ""
          ) : (
            ""
          )}
          <br />
          {readyArchive_isToggled === false ? (
            <div className="container_center gap_dot2rem">
              <div
                className="btn_Pending"
                onClick={() => {
                  if (window.confirm("Confirm Archive?")) {
                    // console.log("⛔REJECT", rowData);
                    set_DB_ticket_log__archive_bytid(
                      rowData?.t_id,
                      tb_Remarks.current,
                      contextValue?.firebase_UserInfo?.email
                    );
                  }
                }}
              >
                <div className="glo_FontSize_1rem">⛔REJECT</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    // {
    //   title: "Aging / Create Date",
    //   field: "gen_dt",
    //   width: "1px",
    //   minWidth: "120px",
    //   render: (rowData) => (
    //     <>
    //       <div
    //         className={`${
    //           rowData.aging_create.split(",")[0] != "0day"
    //             ? "text_Aging_Remain_Day"
    //             : ""
    //         }`}
    //       >{`${
    //         rowData.aging_create.split(",")[0] != "0day"
    //           ? rowData.aging_create.split(",")[0]
    //           : ""
    //       }`}</div>

    //       <div
    //         className={`${
    //           rowData.aging_create.split(",")[1].trim() != "0h"
    //             ? "text_Aging_Remain_Hour"
    //             : ""
    //         }`}
    //         // className="text_Aging_Remain_Hour"
    //       >{`
    //        ${
    //          rowData.aging_create.split(",")[1].trim() != "0h"
    //            ? rowData.aging_create.split(",")[1]
    //            : ""
    //        }`}</div>

    //       <div className="text_Aging_Remain_Min">{`
    //        ${rowData.aging_create.split(",")[2]}`}</div>
    //       <br />
    //       <div>{`${rowData.gen_dt} `}</div>
    //     </>
    //   ),
    // },
    {
      title: "Progress",
      field: "t_outlet",
      width: "1px",

      cellStyle: {
        minWidth: "33rem",
      },
      render: (rowData) => (
        <>
          <div className="text_fontzie_1rem">1.✅ {rowData.ready1_by}</div>
          <div
            className={`text_fontzie_1rem marginLeft_2rem ${
              rowData.t_category == "DailySubmission" ? "text_Gold" : ""
            }`}
          >
            📆 {rowData.ready1_dt}
          </div>
          <div
            className={`text_fontzie_1rem marginLeft_2rem ${
              rowData.t_category == "DailySubmission" ? "text_Gold" : ""
            }`}
          >
            📝{rowData.ready1_notes}
          </div>

          <div className="text_fontzie_1rem">2.✅ {rowData.ready2_by}</div>
          <div
            className={`text_fontzie_1rem marginLeft_2rem ${
              rowData.t_category == "DailySubmission" ? "text_Gold" : ""
            }`}
          >
            📆 {rowData.ready2_dt}
          </div>
          <div
            className={`text_fontzie_1rem marginLeft_2rem ${
              rowData.t_category == "DailySubmission" ? "text_Gold" : ""
            }`}
          >
            📝{rowData.ready2_notes}
          </div>

          <div className="text_fontzie_1rem">3.✅ {rowData.ready3_by}</div>
          <div
            className={`text_fontzie_1rem marginLeft_2rem ${
              rowData.t_category == "DailySubmission" ? "text_Gold" : ""
            }`}
          >
            📆 {rowData.ready3_dt}
          </div>
          <div
            className={`text_fontzie_1rem marginLeft_2rem ${
              rowData.t_category == "DailySubmission" ? "text_Gold" : ""
            }`}
          >
            📝{rowData.ready3_notes}
          </div>
        </>
      ),
    },
    {
      title: "Request Summary",
      field: "t_outlet",
      width: "1px",

      cellStyle: {
        minWidth: "33rem",
      },
      render: (rowData) => (
        <>
          <div className="text_fontzie_1rem">🎪{rowData.t_outlet}</div>

          <div
            className={`text_fontzie_1rem  ${
              rowData.t_category == "DailySubmission" ? "text_Gold" : ""
            }`}
          >
            🏷️{rowData.t_category}
          </div>

          <div className="text_fontzie_1rem marginLeft_2rem">
            📦{rowData.t_option}
          </div>

          <div className="text_fontzie_1rem marginLeft_2rem">
            🧮
            {rowData.t_option == "FnB"
              ? `🧃${rowData.t_option2?.split(",")[0]}, 🥡 ${
                  rowData.t_option2?.split(",")[1]
                }, 🍜${rowData.t_option2?.split(",")[2]}, 🥛${
                  rowData.t_option2?.split(",")[3]
                }`
              : rowData.t_category == "Internet"
              ? `⏳${rowData.t_option2?.split(",")[0]}ms, ⤴️ ${
                  rowData.t_option2?.split(",")[1]
                } mbps, ⤵️${rowData.t_option2?.split(",")[2]} mbps
                `
              : `${rowData.t_option2} unit(s)`}
          </div>

          <div className="text_fontzie_1rem ">📌{rowData.t_remark}</div>
        </>
      ),
    },

    {
      title: "Outlet",
      field: "t_outlet",
      width: "1px",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_outlet}</div>
      ),
    },
    {
      title: "Category",
      field: "t_category",
      width: "1px",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_category}</div>
      ),
    },
    {
      title: "Option",
      field: "t_option",
      width: "10%",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_option}</div>
      ),
    },
    {
      title: "Option2",
      field: "t_option2",
      width: "10%",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_option2}</div>
      ),
    },
    {
      title: "Remark",
      field: "t_remark",
      width: "50%",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_option2}</div>
      ),
    },

    // {
    //   title: "Status Comment at",
    //   field: "status_dt",
    //   minWidth: "130px",
    // },

    // {
    //   title: "Comment by",
    //   render: (rowData) => (
    //     <div className="container_center">
    //       <img className="ico_profilPic" src={rowData.status_by_pic} />
    //       {rowData.status_by}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Last Comment",
    //   field: "t_status_remark",
    //   minWidth: "120px",
    // },
  ];

  // --------------------------------------- func

  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // --------------------------------------- notification

  const showNoti = (_Status, _t_id) => {
    showNotification({
      title: (
        <>
          <div className="flexRow">
            <div className="notif_TiketID">{_t_id}</div>
            <div className="notif_ArrowPointRight">
              <ArrowBigRight />
            </div>
            {_Status == "pending" ? (
              <div className="notif_Pending">Pending</div>
            ) : (
              ""
            )}
            {_Status == "troubleshooting" ? (
              <div className="notif_Troubleshooting">Troubleshooting</div>
            ) : (
              ""
            )}
            {_Status == "solved" ? (
              <div className="notif_Solved">Solved</div>
            ) : (
              ""
            )}
            {_Status == "data-reloaded" ? (
              <div className="notif_Solved">Data Reloaded</div>
            ) : (
              ""
            )}
          </div>
        </>
      ),
      message: "",
      color: "green",
      icon: <Check />,
      autoClose: 10000,
    });
  };

  // --------------------------------------- dialog / model

  const [dialogOpened, setDialogOpened] = useState(false);

  // --------------------------------------- Auth Rights
  // yapwaihon2014@gmail.com
  // skeithzero@gmail.com
  // shawnlwy85@gmail.com
  // tschun1980@gmail.com

  const Auth_FULL = [
    "shawnlwy85@gmail.com",
    "tschun1980@gmail.com",
    "skeithzero@gmail.com",
    "nightshade520@gmail.com",
  ];

  const Full_AUTH__UserCheck = () => {
    let AuthResult = false;

    Auth_FULL.forEach((x) => {
      if (contextValue?.firebase_UserInfo?.email === x) AuthResult = true;
    });

    return AuthResult;
  };

  const Auth_Admin1 = ["yapwaihon2014@gmail.com", "shawnlwy85@gmail.com"];

  const Admin1_AUTH__UserCheck = () => {
    let AuthResult = false;

    Auth_Admin1.forEach((x) => {
      if (contextValue?.firebase_UserInfo?.email === x) AuthResult = true;
    });
    return AuthResult;
  };

  // --------------------------------------- global variable
  const [selected_RowData, setSelected_RowData] = useState([]);
  const [cb_Show_Solved, setCb_Show_Solved] = useState(false);

  const tb_Remarks = useRef("");

  const [ready1_isToggled, setReady1_isToggled] = useState(true); //default
  const [ready2_isToggled, setReady2_isToggled] = useState(false);
  const [ready3_isToggled, setReady3_isToggled] = useState(false);
  const [ready4_isToggled, setReady4_isToggled] = useState(false);
  const [readyArchive_isToggled, setArchive_isToggled] = useState(false);

  const Reload_DB = () => {
    get_DB_ticket_log__ready1();
    get_DB_ticket_log__ready2();
    get_DB_ticket_log__ready3();
    get_DB_ticket_log__ready4();
    get_DB_ticket_log__Archive();
  };

  useEffect(() => {
    Reload_DB();

    // get_DB_ticket_log_all__Ready1();
    // get_DB_ticket_log_all_Solved();
    // console.log("Page_createTicket", contextValue);
  }, []);

  return (
    <>
      <COM_NavBar_Global />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <h1>My Request</h1>
      <p> KokWah & Skeitch handle #1</p>
      <p> William handle #2,3</p>
      <br />
      <b>
        <p>Create New Request at "Create Tiket", then select "Request" </p>
      </b>
      <div
        className={`${
          readyArchive_isToggled
            ? "myReq_TabArchive_on"
            : "myReq_TabArchive_off"
        } myReq_Tab_Container`}
        onClick={() => {
          setReady1_isToggled(false);
          setReady2_isToggled(false);
          setReady3_isToggled(false);
          setReady4_isToggled(false);
          setArchive_isToggled((pre) => !pre);
          Reload_DB();
          // console.log("get_DB_ticket_log__ready1");
        }}
      >
        Show Archive Data ({DB_Archive?.length})
      </div>
      <div className="pageBody_Container">
        <div className="pageBody_Container2">
          <br />
          <div className="flexRow">
            <div
              className={`${
                ready1_isToggled ? "myReq_Tab1_on" : "myReq_Tab1_off"
              } myReq_Tab_Container`}
              onClick={() => {
                setReady1_isToggled((pre) => !pre);
                setReady2_isToggled(false);
                setReady3_isToggled(false);
                setReady4_isToggled(false);
                setArchive_isToggled(false);
                get_DB_ticket_log__ready1();
                Reload_DB();
                // console.log("get_DB_ticket_log__ready1");
              }}
            >
              ✅1. Waiting Approval ({DB_Ready1?.length})
            </div>
            <div
              className={`${
                ready2_isToggled ? "myReq_Tab2_on" : "myReq_Tab2_off"
              } myReq_Tab_Container`}
              onClick={() => {
                setReady1_isToggled(false);
                setReady2_isToggled((pre) => !pre);
                setReady3_isToggled(false);
                setReady4_isToggled(false);
                setArchive_isToggled(false);
                get_DB_ticket_log__ready2();
                Reload_DB();
                // console.log("get_DB_ticket_log__ready2");
              }}
            >
              ✅2. Ready To Collect ({DB_Ready2?.length})
            </div>
            <div
              className={`${
                ready3_isToggled ? "myReq_Tab3_on" : "myReq_Tab3_off"
              } myReq_Tab_Container`}
              onClick={() => {
                setReady1_isToggled(false);
                setReady2_isToggled(false);
                setReady3_isToggled((pre) => !pre);
                setReady4_isToggled(false);
                setArchive_isToggled(false);
                get_DB_ticket_log__ready3();
                Reload_DB();
                // console.log("get_DB_ticket_log__ready3");
              }}
            >
              ✅3. Spoiled Item Collected ({DB_Ready3?.length})
            </div>
            <div
              className={`${
                ready4_isToggled ? "myReq_Tab4_on" : "myReq_Tab4_off"
              } myReq_Tab_Container`}
              onClick={() => {
                setReady1_isToggled(false);
                setReady2_isToggled(false);
                setReady3_isToggled(false);
                setReady4_isToggled((pre) => !pre);
                setArchive_isToggled(false);
                get_DB_ticket_log__ready4();
                Reload_DB();
                // console.log("get_DB_ticket_log__ready4");
              }}
            >
              4. Completed ({DB_Ready4?.length})
            </div>
          </div>
          <br />
          <MaterialTable
            style={{
              MuiTablePaginationRoot: "gold",
              color: "white",
              // background: "rgba(0, 0, 100, .0)",
              backgroundImage:
                "linear-gradient(to bottom right, #ffd900, #ffa90a)",
              boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
              backdropFilter: "blur( 11.0px )",
              "&::webkit-backdrop-filter": {
                webkitBackdropFilter: "blur( 18.0px )",
              },
            }}
            title={
              <div style={{ color: "black" }}>
                {ready1_isToggled ? (
                  <h1>✅1. Waiting Approval ({DB_Ready1?.length})</h1>
                ) : ready2_isToggled ? (
                  <h1>✅2. Ready To Collect ({DB_Ready2?.length})</h1>
                ) : ready3_isToggled ? (
                  <h1>✅3. Spoiled Item Collected ({DB_Ready3?.length})</h1>
                ) : ready4_isToggled ? (
                  <h1> 4. Completed ({DB_Ready4?.length})</h1>
                ) : readyArchive_isToggled ? (
                  <h1>Archive Data</h1>
                ) : (
                  ""
                )}
              </div>
            }
            columns={columns}
            data={
              ready1_isToggled
                ? DB_Ready1
                : ready2_isToggled
                ? DB_Ready2
                : ready3_isToggled
                ? DB_Ready3
                : ready4_isToggled
                ? DB_Ready4
                : readyArchive_isToggled
                ? DB_Archive
                : []
            }
            options={{
              headerStyle: {
                background: "rgba(255, 255, 255, .2)",
                border: "none",
              },
              exportButton: true,
              rowStyle: (rowData) => ({
                backgroundColor: "#1a0000",
                color: "#ffffff",
                fontSize: 12,
              }),
              filtering: true,
              // grouping: true,
              // exportAllData: true,
              pageSize: 20,
              pageSizeOptions: [100, 200, , 500, 1000],
              paginationType: "stepped",
              // actionsColumnIndex: -1
              // fixedColumns: {
              //     left: 1,
              // }
              // exportMenu: [
              //   {
              //     label: "Export PDF",
              //     exportFunc: (cols, datas) =>
              //       ExportPdf(cols, datas, "myPdfFileName"),
              //   },
              //   {
              //     label: "Export CSV",
              //     exportFunc: (cols, datas) =>
              //       ExportCsv(cols, datas, "myCsvFileName"),
              //   },
              // ],
            }}
            // onRowClick={(evt, selectedRow) => {
            // alert(selectedRow.id);
            // }}
            // actions={[
            //   {
            //     icon: "save",
            //     tooltip: "Save User",
            //     onClick: (event, rowData) => {
            //       // handleClickOpen_1_2();
            //       // getDB_patroninfo_byid(rowData.id);
            //       // alert(rowData.id);
            //     },
            //   },
            // ]}
            components={{
              Action: (props) => (
                <>
                  {/* <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "gold" }}>DONE</div>
              </Button>
              <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "#2bc3ff" }}>EDIT</div>
              </Button> */}
                </>
              ),
            }}
          />

          <Modal
            opened={dialogOpened}
            onClose={() => setDialogOpened(false)}
            title={
              <>{`My Request Ticket Details : Ticket ID = ${selected_RowData.t_id}`}</>
            }
            centered
            size="full"
            transition="slide-down"
            transitionDuration={600}
            transitionTimingFunction="ease"
            overflow="inside"
          >
            <br />
            <br />
            <COM_ChatRoom_Lobby
              p_roomID={"AweSoMe_" + selected_RowData.t_id}
              p_userInfo={{
                uid: contextValue?.firebase_UserInfo?.uid,
                u_name: contextValue?.firebase_UserInfo?.displayName,
                u_pic_url: contextValue?.firebase_UserInfo?.photoURL,
              }}
              p_RowData={selected_RowData}
            />
          </Modal>
        </div>
      </div>
    </>
  );
}
