import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import {
  RadioGroup,
  Radio,
  Autocomplete,
  TextInput,
  Button,
  Checkbox,
  CheckboxGroup,
  Modal,
  Select,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import COM_FileUpload from "../Component/COM_FileUpload";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";

import { UserContext } from "../UserContext";

// -------------------------------------------------  firebase - 1/2
// import { authentication } from "../firebase-config";
// import {
//   signInWithPopup,
//   GoogleAuthProvider,
//   FacebookAuthProvider,
//   signOut,
//   onAuthStateChanged,
// } from "firebase/auth";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

const COMP_Outlet = ({ selected_Outlet, setSelected_Outlet }) => {
  const contextValue = useContext(UserContext);

  return (
    <>
      <Select
        value={selected_Outlet}
        onChange={setSelected_Outlet}
        required
        size="xl"
        limit={100}
        maxDropdownHeight={600}
        label={
          <div className="flexRow">
            <div>{`1. Outlet : `}</div>
            <div className="selectedValue">{` ${selected_Outlet}`}</div>
          </div>
        }
        // placeholder=""
        // data={["React", "Angular", "Svelte", "Vue"]}
        // data={outletsInfo.map((x) => x.outlet_fullname)}
        data={contextValue?.bd_UserInfo?.u_outlets?.toString()?.split(",")}
      />
      {/* <Autocomplete
        value={selected_Outlet}
        onChange={setSelected_Outlet}
        required
        size="xl"
        limit={100}
        label={
          <div className="flexRow">
            <div>{`1. Outlet : `}</div>
            <div className="selectedValue">{` ${selected_Outlet}`}</div>
          </div>
        }
        placeholder=""
        // data={["React", "Angular", "Svelte", "Vue"]}
        // data={outletsInfo.map((x) => x.outlet_fullname)}
        data={contextValue?.bd_UserInfo?.u_outlets?.toString()?.split(",")}
      /> */}
    </>
  );
};

export default function X({ parrent_DB_userinfo }) {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // -------------------------------------------------  firebase - 2/2
  // const [firebase_userInfo, setFirebase_userInfo] = useState({});
  // {
  // console.log("firebase_userInfo", firebase_userInfo);
  // }

  // onAuthStateChanged(authentication, (currentUser) => {
  //   setFirebase_userInfo(currentUser);
  // });

  // -------------------------------------------------  db

  // get outlet Info
  const [outletsInfo, setOutletsInfo] = useState([]);
  const getDB_OutletInfo = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__outlet__InfoAll`)
      .then((res) => {
        // console.log("getDB_OutletInfo", res.data);
        setOutletsInfo(res.data);
      });
  };

  // get game name list
  const [gameNameList, setGameNameList] = useState([]);
  const getDB_GameNameList = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__ticket_log__gamename_List`
      )
      .then((res) => {
        // console.log("getDB_GameNameList", res.data);
        setGameNameList(res.data.map((x) => x.t_option2));
        // setOutletsInfo(res.data);
      });
  };

  // get request name list
  const [requestNameList, setRequestNameList] = useState([]);
  const getDB_RequestNameList = () => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__ticket_log__requestname_List`
      )
      .then((res) => {
        // console.log("getDB_GameNameList", res.data);
        setRequestNameList(res.data.map((x) => x.t_option));
        // setOutletsInfo(res.data);
      });
  };

  //--------------------------------------------------------Telegram Info
  let allCafeTelegramID = [
    "Gift BBA",
    "Gift BBK",
    "Gift Bukit Angsana",
    "Gift Bukit Serdang",
    "Gift Cheras Perdana",
    "Gift Equine Park",
    "Gift Eve Suit",
    "Gift Impian",
    "Gift Kajang Indah",
    "Gift Kepong Baru",
    "Gift Kota Damansara",
    "Gift Lembah Maju",
    "Gift Manjalara",
    "Gift Nirwarna",
    "Gift OUG",
    "Gift Pandan Indah",
    "Gift Sg Buloh",
    "Gift Sg Long",
    "Gift Sri Manja",
    "Gift Suntex",
    "Redsea PS",
    "Redsea PV12",
    "Redsea TBR",
    "Redsea WM",
  ];

  const teleUsers = [
    //------------------------------------- admin
    {
      telegranId: "1093252356",
      name: "Shawn",
      cafesInCharge: allCafeTelegramID,
    },
    {
      telegranId: "5113452260",
      name: "Eric",
      cafesInCharge: allCafeTelegramID,
    },
    {
      telegranId: "1143431696",
      name: "Chun",
      cafesInCharge: allCafeTelegramID,
    },
    //------------------------------------- gift super Head

    {
      telegranId: "1152358466",
      name: "LYW",
      // cafesInCharge: allCafeTelegramID,
      cafesInCharge: ["Gift Bukit Serdang", "Gift Equine Park"],
    },
    {
      telegranId: "348309614",
      name: "Wai Onn",
      // cafesInCharge: allCafeTelegramID,
      cafesInCharge: ["Gift OUG", "Gift Sri Manja"],
    },
    // {
    //   telegranId: "5277349670",
    //   name: "WAH (Kok)",
    //   cafesInCharge: allCafeTelegramID,
    //   // [""]
    // },

    //------------------------------------- gift super

    {
      telegranId: "1685521959",
      name: "Amirul",
      cafesInCharge: ["Gift Kajang Indah"],
    },
    {
      telegranId: "1793283430",
      name: "Adrian Thong",
      cafesInCharge: [
        "Gift Bukit Angsana",
        "Gift Cheras Perdana",
        "Gift Sg Long",
      ],
    },
    {
      telegranId: "1259701615",
      name: "Mohd Firdaus Kurus",
      cafesInCharge: ["Gift Sg Buloh", "Gift Manjalara", "Gift Kepong Baru"],
    },
    {
      telegranId: "1168093421",
      name: "Shaf",
      cafesInCharge: ["Gift BBA", "Gift Suntex", "Gift Impian"],
    },
    {
      telegranId: "1587303785",
      name: "Wan",
      cafesInCharge: ["Gift Pandan Indah", "Gift Nirwarna", "Gift Lembah Maju"],
    },
    { telegranId: "986677258", name: "Aqil", cafesInCharge: ["Gift BBK"] },
    {
      telegranId: "1635105278",
      name: "Fikri",
      cafesInCharge: ["Gift Eve Suit", "Gift Kota Damansara"],
    },
    {
      telegranId: "759531574",
      name: "Teha",
      cafesInCharge: ["Gift Sri Manja", "Gift OUG"],
    },
  ];

  // add new ticket
  const addDB_NewTicket = (
    _t_outlet,
    _t_category,
    _t_option,
    _t_option2,
    _t_remark,
    _t_uuid,
    _t_status,
    _gen_by,
    _gen_by_uuid,
    _gen_by_pic
  ) => {
    console.log("info 1", {
      t_outlet: _t_outlet,
      t_category: _t_category,
      t_option: _t_option,
      t_option2: _t_option2.toLowerCase().trim(),
      t_remark: _t_remark,
      t_uuid: _t_uuid,
      t_status: _t_status,
      gen_by: _gen_by,
      gen_by_uuid: _gen_by_uuid,
      gen_by_pic: _gen_by_pic,
    });

    if (_t_category === "Request") {
    }

    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/add_eclipse_awesomelist__ticket_log__new`,
        {
          t_outlet: _t_outlet,
          t_category: _t_category,
          t_option: _t_option,
          t_option2: _t_option2.toLowerCase().trim(),
          t_remark: _t_remark,
          t_uuid: _t_uuid,
          t_status: _t_status,
          gen_by: _gen_by,
          gen_by_uuid: _gen_by_uuid,
          gen_by_pic: _gen_by_pic,
        }
      )
      .then((res) => {
        // console.log("addDB_NewTicket", res.data);
        let last_insertID = res.data[5][0].newTicketID;

        if (res.data[3].affectedRows > 0) {
          // ------------------------ send telegram "My Request Hardware Ticket"
          let RequestTicket_String =
            "🔔 New REQUEST Ticket : " +
            last_insertID +
            "\n\n" +
            "🎪 Outlet : " +
            _t_outlet +
            "\n" +
            "🏷️ Category : " +
            _t_category +
            "\n\n" +
            "📦 Item : " +
            _t_option +
            "\n" +
            "🧮 Quantity : " +
            _t_option2.toLowerCase().trim() +
            "\n\n" +
            "📌 Remark : " +
            _t_remark +
            "\n\n" +
            "👤 Created by : " +
            _gen_by;

          if (_t_category?.includes("Request")) {
            let toWho = [
              "1093252356", // shwan
              "1143431696", // chun
              "5097555194", // skeith
            ];
            Send_Telegram_Notification(toWho, RequestTicket_String);
          }

          // ------------------------ send telegram notification
          let Final_OrderString =
            "🔔 New Ticket : " +
            last_insertID +
            "\n\n" +
            "🎪 Outlet : " +
            _t_outlet +
            "\n\n" +
            "🏷️ Category : " +
            _t_category +
            "\n" +
            "🔍 Option 1 : " +
            _t_option +
            "\n" +
            "🔍 Option 2 : " +
            _t_option2.toLowerCase().trim() +
            "\n" +
            "📌 Remark : " +
            _t_remark +
            "\n\n" +
            "👤 Created by : " +
            _gen_by;

          // find user incharge cafe (admin / super)
          const userInChargeCafe =
            contextValue?.bd_UserInfo?.u_outlets?.split(",")[0] == ""
              ? contextValue?.bd_UserInfo?.u_outlets?.split(",")[1]
              : contextValue?.bd_UserInfo?.u_outlets?.split(",")[0];
          // console.log("## userInChargeCafe", userInChargeCafe);

          // get the telegram id
          const toTelegramUserId = teleUsers
            .filter((x) => {
              return (
                x.cafesInCharge.includes(userInChargeCafe) &&
                x.telegranId !== "1143431696" // filter chun out
              );
            })
            .map((y) => {
              return y.telegranId;
            });

          // console.log("## toTelegramUserId", toTelegramUserId);

          axios
            .post(
              `${REACT_APP_ECLIPSE_API}/add__telegram_api__sendMessage__AweSoMe`,
              {
                chat_ids: toTelegramUserId,
                text: Final_OrderString,
              }
            )
            .then((data) => {
              // getDB_ListItem();
              // console.log("data", data);
            });

          // axios
          //   .post(
          //     `${REACT_APP_ECLIPSE_API}/add__telegram_api__sendMessage__AweSoMe`,
          //     {
          //       chat_ids: [
          //         //------------------------------------- admin
          //         "1093252356", // shawn
          //         // "5113452260", // Eric
          //         // "1143431696", // chun

          //         //------------------------------------- gift super
          //         // "1152358466", // 1. LYW
          //         // "348309614", // 2. Wai Onn
          //         // "1793283430", // 3. Adrian Thong
          //         // "1259701615", // 4. mohd firdaus kurus
          //         // "1168093421", // 5. shaf
          //         // "1587303785", // 6. Wan
          //         // "5277349670", // 7. WAH (Kok)
          //         // "986677258", // 8. Aqil
          //         // "1635105278", // 9. fikri

          //         //------------------------------------- eclipse marketing
          //         // "1188960895", // tony
          //         // "5177371452", // des
          //         // "1629617723", // william
          //         // "5025049768", // ricci
          //       ],
          //       text: Final_OrderString,
          //     }
          //   )
          //   .then((data) => {
          //     // getDB_ListItem();
          //     // console.log("data", data);
          //   });

          getDB_GameNameList();
          alert("SUCCESSFULLY! Created New Ticket");
        } else {
          alert("FAILED! to Created New Ticket");
        }
      });
  };

  // send Telegram Notification
  const Send_Telegram_Notification = (chat_ids, text) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/add__telegram_api__sendMessage__AweSoMe`,
        {
          chat_ids,
          text,
        }
      )
      .then((data) => {
        // getDB_ListItem();
        // console.log("data", data);
      });
  };

  const ticket_Categories = [
    // "Request",
    "Internet",
    "WiFiServer",
    "MovieServer",
    "SaveGameServer",
    "Game",
    "EQHW",
    "AirCond",
    "Light",
    "Stock",
    "Other",
  ];

  if (contextValue?.bd_UserInfo?.u_role === "admin") {
    ticket_Categories.push("Request");
  }

  const stock_Options = ["FnB"];
  const internet_Options = ["All Good", "Problem Found"];
  const wiFiServer_Options = ["All Good", "Problem Found"];
  const moive_Options = ["All Good", "Cant Connect", "Request", "Other"];
  const savegame_Options = [
    "All Good",
    "Cant Connect",
    "Add New Save Game",
    "Other",
  ];

  const game_Options = [
    "Update Required",
    "Cant Open",
    "Maintenance",
    "Request",
    "Other",
  ];
  const EQHW_Options1 = [
    "Keyboard",
    "Mouse",
    "Mouse Pad",
    "Headphone",
    "Monitor",
    "CPU",
    "Printer",
    "CCTV",
    "Fidge",
    "Table",
    "Chair",
    "Water Supply",
    "Electric Door",
    "Toilet",
    "Santitizer",
    "EQHW Other",
  ];
  const EQHW_Options2 = ["All Good", "Broke", "Missing", "Other"];

  const AirCond_Options1 = [
    "AC 1",
    "AC 2",
    "AC 3",
    "AC 4",
    "AC 5",
    "AC 6",
    "AC 7",
    "AC 8",
    "AC 9",
    "AC 10",
    "AC 11",
    "AC 12",
    "AC Other",
  ];
  const AirCond_Options2 = [
    "All Good",
    "Power Failure",
    "ON but Warm",
    "NOT Cool Enough",
    "Water Leaking",
    "Light Beeping",
    "Jump Switch",
    "Other",
  ];
  const Light_Options1 = ["Fluorescent Tube", "Other"];
  const Light_Options2 = ["No Power", "Disco", "Jump Switch", "Dark", "Other"];

  // --------------------------------------- radio
  const [RB_Value, setRB_Value] = useState([]);
  const [totalSubmitList, setTotalSubmitList] = useState([]);

  // --------------------------------------- dialog / model
  const [dialogOpened, setDialogOpened] = useState(false);

  // --------------------------------------- Global Variable
  const [selected_Outlet, setSelected_Outlet] = useState("");
  const [selected_Category, setSelected_Category] = useState("");

  const [selected_UUID, setSelected_UUID] = useState("");

  const selected__Option = useRef("");

  const selected__Option2a = useRef("");
  const selected__Option2b = useRef("");
  const selected__Option2c = useRef("");
  const selected__Option2d = useRef("");

  const selected_Remark = useRef("");

  // const [dailySubmit_AllGood_Count, setDailySubmit_AllGood_Count] = useState(
  //   []
  // );
  const dailySubmit_AllGood_Count = useRef([]);

  const [dailySubmit_ProblemFound_Count, setDailySubmit_ProblemFound_Count] =
    useState([]);

  // --------------------------------------- Components -  Option

  const COMP_Category = () => {
    return (
      <>
        <RadioGroup
          value={selected_Category}
          onChange={(e) => {
            setSelected_Category(e);
            reset_FromData_OptionsOnly();
          }}
          label={
            <div className="flexRow">
              <div>{`2. Category : `}</div>
              <div className="selectedValue">{` ${selected_Category}`}</div>
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {ticket_Categories.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
      </>
    );
  };

  const COMP_Stock = ({}) => {
    return (
      <>
        <RadioGroup
          // value={selected__Option}
          // onChange={(e) => {
          //   selected__Option.current = e;
          // }}.
          onChange={(e) => {
            if (selected__Option != undefined) {
              selected__Option.current = e;
            } else {
              selected__Option.current = e;
            }
            console.log(
              "COMP_Internet Option 1: selected__Option.current",
              selected__Option.current
            );
          }}
          label={
            <div className="flexRow">
              <div>{`Stock Option 1: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {stock_Options.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`Stock Option 2.a : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2a}`}</div> */}
            </div>
          }
          required
          placeholder="Can Drinks"
          size="xl"
          // value={selected__Option2a}
          // defaultValue="0"
          onChange={(event) =>
            (selected__Option2a.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            ))
          }
        />
        <div className="text_Tips">TIP: Quantity</div>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`Stock Option 2.b : `}</div>
              <div className="selectedValue">{` ${selected__Option2b.current}`}</div>
            </div>
          }
          required
          placeholder="Snack"
          size="xl"
          // value={selected__Option2b.current}
          onChange={(event) =>
            // setSelected__Option2b(
            //   event.currentTarget.value.replace(/[^0-9.]/g, "")
            // )
            (selected__Option2b.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            ))
          }
        />
        <div className="text_Tips">TIP: Quantity</div>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`Stock Option 2.c : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2c}`}</div> */}
            </div>
          }
          required
          placeholder="Instant Noodles"
          size="xl"
          // value={selected__Option2c}
          onChange={(event) =>
            (selected__Option2c.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            ))
          }
        />
        <div className="text_Tips">TIP: Quantity</div>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`Stock Option 2.d : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2c}`}</div> */}
            </div>
          }
          required
          placeholder="Mineral Water"
          size="xl"
          // value={selected__Option2c}
          onChange={(event) =>
            (selected__Option2d.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            ))
          }
        />
        <div className="text_Tips">TIP: Quantity</div>
      </>
    );
  };

  const COMP_Internet = ({}) => {
    return (
      <>
        <RadioGroup
          // value={selected__Option}
          // onChange={(e) => {
          //   selected__Option.current = e;
          // }}.
          onChange={(e) => {
            if (selected__Option != undefined) {
              selected__Option.current = e;
            } else {
              selected__Option.current = e;
            }
            console.log(
              "COMP_Internet Option 1: selected__Option.current",
              selected__Option.current
            );
          }}
          label={
            <div className="flexRow">
              <div>{`Internt Option 1: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {internet_Options.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`Internt Option 2.a : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2a}`}</div> */}
            </div>
          }
          required
          placeholder="Line Ping (ms)"
          size="xl"
          // value={selected__Option2a}
          onChange={(event) =>
            (selected__Option2a.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            ))
          }
        />
        <div className="text_Tips">
          TIP: Answer Format: 3 (If you get over 30, test one more time, if
          still over 30, contact your supervisor immediately)
        </div>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`Internt Option 2.b : `}</div>
              <div className="selectedValue">{` ${selected__Option2b.current}`}</div>
            </div>
          }
          required
          placeholder="Line Download Speed"
          size="xl"
          // value={selected__Option2b.current}
          onChange={(event) =>
            // setSelected__Option2b(
            //   event.currentTarget.value.replace(/[^0-9.]/g, "")
            // )
            (selected__Option2b.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            ))
          }
        />
        <div className="text_Tips">
          TIP: Answer Format: 755.44 (If you get lower than 200, test one more
          time, if still lower than 200, contact your supervisor immediately.)
        </div>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`Internt Option 2.c : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2c}`}</div> */}
            </div>
          }
          required
          placeholder="Line Upload Speed"
          size="xl"
          // value={selected__Option2c}
          onChange={(event) =>
            (selected__Option2c.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            ))
          }
        />
        <div className="text_Tips">
          TIP: Answer Format: 195.45 (If you get lower than 50, test one more
          time, if still lower than 50, contact your supervisor immediately.)
        </div>
      </>
    );
  };
  const COMP_WiFiServer = ({}) => {
    return (
      <>
        <RadioGroup
          // value={selected__Option}
          // onChange={(e) => {
          //   selected__Option.current = e;
          // }}.
          onChange={(e) => {
            if (selected__Option != undefined) {
              selected__Option.current = e;
            } else {
              selected__Option.current = e;
            }
            // console.log(
            //   "COMP_WiFiServer Option 1: selected__Option.current",
            //   selected__Option.current
            // );
          }}
          label={
            <div className="flexRow">
              <div>{`WiFi Server Option 1: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {wiFiServer_Options.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`WiFi Server Option 2.a : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2a}`}</div> */}
            </div>
          }
          required
          placeholder="WiFi Ping (ms)"
          size="xl"
          // value={selected__Option2a}
          onChange={(event) =>
            (selected__Option2a.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            ))
          }
        />
        <div className="text_Tips">
          TIP: Answer Format: 3 (If you get over 30, test one more time, if
          still over 30, contact your supervisor immediately)
        </div>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`WiFi Server Option 2.b : `}</div>
              <div className="selectedValue">{` ${selected__Option2b.current}`}</div>
            </div>
          }
          required
          placeholder="WiFi Download Speed"
          size="xl"
          // value={selected__Option2b.current}
          onChange={(event) =>
            // setSelected__Option2b(
            //   event.currentTarget.value.replace(/[^0-9.]/g, "")
            // )
            (selected__Option2b.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            ))
          }
        />
        <div className="text_Tips">
          TIP: Answer Format: 755.44 (If you get lower than 200, test one more
          time, if still lower than 200, contact your supervisor immediately.)
        </div>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`WiFi Server Option 2.c : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2c}`}</div> */}
            </div>
          }
          required
          placeholder="WiFi Upload Speed"
          size="xl"
          // value={selected__Option2c}
          onChange={(event) =>
            (selected__Option2c.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            ))
          }
        />
        <div className="text_Tips">
          TIP: Answer Format: 195.45 (If you get lower than 50, test one more
          time, if still lower than 50, contact your supervisor immediately.)
        </div>
      </>
    );
  };

  const COMP_MovieServer = () => {
    return (
      <>
        <RadioGroup
          // value={selected__Option}
          onChange={(e) => (selected__Option.current = e)}
          label={
            <div className="flexRow">
              <div>{`Movie Option 1: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {moive_Options.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
        <br />
        <br />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`MovieServer Option 2.a : `}</div>
              {/* <div className="selectedValue">{` ${selected_Remark}`}</div> */}
            </div>
          }
          required
          placeholder="Other"
          size="xl"
          // value={selected__Option2a}
          onChange={(event) =>
            (selected__Option2a.current = event.currentTarget.value)
          }
        />
        <div className="text_Tips">
          TIP: Just play a movie from the movie server to test.
        </div>
      </>
    );
  };

  const COMP_SaveGameServer = () => {
    return (
      <>
        <RadioGroup
          // value={selected__Option}
          onChange={(e) => (selected__Option.current = e)}
          label={
            <div className="flexRow">
              <div>{`SaveGameServer Option 1: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {savegame_Options.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
        <br />
        <br />
        <br />
        <Autocomplete
          // value={selected__Option2a}
          onChange={(value) => {
            selected__Option2a.current = value;
          }}
          required
          size="xl"
          label={
            <div className="flexRow">
              <div>{`SaveGameServer Option 2.a: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2a}`}</div> */}
            </div>
          }
          placeholder=""
          // data={["React", "Angular", "Svelte", "Vue"]}
          // data={outletsInfo.map((x) => x.outlet_fullname)}
          data={gameNameList}
        />

        <div className="text_Tips">
          TIP: Create an ID and login, can login = all good
        </div>
      </>
    );
  };

  const COMP_Game = ({
    p_selected__Option2,
    p_selected__Option2_Disabled = false,
  }) => {
    if (p_selected__Option2 != "") {
      selected__Option2a.current = p_selected__Option2;
    }

    return (
      <>
        <RadioGroup
          // value={selected__Option}
          onChange={(e) => (selected__Option.current = e)}
          label={
            <div className="flexRow">
              <div>{`Game Option 1: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {game_Options.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
        <br />
        <Autocomplete
          value={p_selected__Option2}
          disabled={p_selected__Option2_Disabled}
          onChange={(value) => {
            selected__Option2a.current = value;
          }}
          required
          size="xl"
          label={
            <div className="flexRow">
              <div>{`Game Option 2: `}</div>
              {/* <div className="selectedValue">{` ${selected_Outlet}`}</div> */}
            </div>
          }
          placeholder=""
          // data={["React", "Angular", "Svelte", "Vue"]}
          // data={outletsInfo.map((x) => x.outlet_fullname)}
          data={gameNameList}
        />
        <div className="text_Tips">
          TIP: (Any Update? Can it run?) Please post in Facebook group if it
          needs update or has issue. If you notice there's a game that is hot in
          your branch and it is not on the list, feel free to check and let us
          know if it needs update.
        </div>
      </>
    );
  };

  const COMP_EQHW = ({ p_selected__Option }) => {
    selected__Option.current = p_selected__Option;
    return (
      <>
        <RadioGroup
          // value={p_selected__Option}
          defaultValue={p_selected__Option}
          onChange={(e) => {
            if (selected__Option != undefined) {
              selected__Option.current = e;
            } else {
              selected__Option.current = e;
            }
            console.log(
              "COMP_Internet Option 1: selected__Option.current",
              selected__Option.current
            );
          }}
          label={
            <div className="flexRow">
              <div>{`EQHW Option 1: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option.current}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {EQHW_Options1.map((x) => (
            <Radio
              value={x}
              label={
                <div className="glo_RadioBtn" style={{}}>
                  {x}
                </div>
              }
              disabled={
                x != p_selected__Option
                  ? p_selected__Option != "showAll"
                    ? true
                    : false
                  : false
              }
            />
          ))}
        </RadioGroup>
        <br />
        <RadioGroup
          // value={selected__Option2a.current}
          onChange={(e) => (selected__Option2a.current = e)}
          label={
            <div className="flexRow">
              <div>{`EQHW Option 2.a : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2a.current}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {EQHW_Options2.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`EQHW Option 2.b : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2b}`}</div> */}
            </div>
          }
          required
          placeholder="ie. Location / Zone / PC NO / CCTV Chnnel , if All Good not need fill in  "
          size="xl"
          // value={selected__Option2b.current}
          onChange={(event) => {
            // event.preventDefault();
            // setSelected__Option2b(event.currentTarget.value);
            selected__Option2b.current = event.currentTarget.value;
          }}
        />
        <div className="text_Tips">
          TIP: Are all the equipment still in good condition? Temporarily remove
          the power plug if something is malfunction.
        </div>
      </>
    );
  };

  const COMP_AirCond = ({ p_selected__Option }) => {
    selected__Option.current = p_selected__Option;

    return (
      <>
        <RadioGroup
          // value={selected__Option}
          defaultValue={p_selected__Option}
          onChange={(e) => {
            if (selected__Option != undefined) {
              selected__Option.current = e;
            } else {
              selected__Option.current = e;
            }
            console.log(
              "COMP_Internet Option 1: selected__Option.current",
              selected__Option.current
            );
          }}
          label={
            <div className="flexRow">
              <div>{`AirCond Option 1: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {AirCond_Options1.map((x) => (
            <Radio
              value={x}
              label={x}
              disabled={
                x != p_selected__Option
                  ? p_selected__Option != "showAll"
                    ? true
                    : false
                  : false
              }
            />
          ))}
        </RadioGroup>
        <br />
        <RadioGroup
          // value={selected__Option2a}
          // onChange={(e) => selected__Option2a(e)}
          onChange={(e) => (selected__Option2a.current = e)}
          label={
            <div className="flexRow">
              <div>{`AirCond Option 2.a: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2a}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {AirCond_Options2.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
        <br />
        <div className="text_Tips">
          TIP: Are all the Air Cond working properly? Go test with your hand and
          feel the wind.
        </div>
      </>
    );
  };

  const COMP_Light = () => {
    return (
      <>
        <RadioGroup
          // value={selected__Option}
          onChange={(e) => (selected__Option.current = e)}
          label={
            <div className="flexRow">
              <div>{`Light Option 1: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {Light_Options1.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
        <br />
        <RadioGroup
          // value={selected__Option2a}
          onChange={(e) => (selected__Option2a.current = e)}
          label={
            <div className="flexRow">
              <div>{`Light Option 2.a: `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2a}`}</div> */}
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {Light_Options2.map((x) => (
            <Radio value={x} label={<div className="glo_RadioBtn">{x}</div>} />
          ))}
        </RadioGroup>
        <br />
        <div className="text_Tips">
          TIP: Are all the Air Cond working properly? Go test with your hand and
          feel the wind.
        </div>
      </>
    );
  };

  const COMP_Request = () => {
    return (
      <>
        <Autocomplete
          // value={selected__Option2a}
          onChange={(value) => {
            selected__Option.current = value;
            // console.log(
            //   "COMP_Request Option 1: selected__Option.current",
            //   selected__Option.current
            // );
          }}
          required
          size="xl"
          label={
            <div className="flexRow">
              <div>{`(Option 1) Request Item : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2a}`}</div> */}
            </div>
          }
          placeholder=""
          // data={["React", "Angular", "Svelte", "Vue"]}
          // data={outletsInfo.map((x) => x.outlet_fullname)}
          data={requestNameList}
        />

        <TextInput
          label={
            <div className="flexRow">
              <div>{`(Option 2.a) Request Quantity : `}</div>
              {/* <div className="selectedValue">{` ${selected__Option2a}`}</div> */}
            </div>
          }
          required
          placeholder="Item Quantity"
          size="xl"
          // value={selected__Option2a}
          // defaultValue="0"
          onChange={(event) => {
            selected__Option2a.current = event.currentTarget.value.replace(
              /[^0-9.]/g,
              ""
            );
            console.log(
              "COMP_Request Option 2a: selected__Option2a.current",
              selected__Option2a.current
            );
          }}
        />
      </>
    );
  };

  const COMP_Other = () => {
    return (
      <>
        <TextInput
          label={
            <div className="flexRow">
              <div>{`Other Option 1 : `}</div>
              {/* <div className="selectedValue">{` ${selected_Remark}`}</div> */}
            </div>
          }
          required
          placeholder="Title"
          size="xl"
          // value={selected__Option}
          onChange={(event) =>
            (selected__Option.current = event.currentTarget.value)
          }
        />
        <br />
        <TextInput
          label={
            <div className="flexRow">
              <div>{`Internt Option 2.a : `}</div>
              {/* <div className="selectedValue">{` ${selected_Remark}`}</div> */}
            </div>
          }
          required
          placeholder="Description"
          size="xl"
          // value={selected__Option2a}
          onChange={(event) =>
            (selected__Option2a.current = event.currentTarget.value)
          }
        />
        <div className="text_Tips">TIP: Anything</div>
      </>
    );
  };

  const COMP_Remark = () => {
    return (
      <>
        <TextInput
          label={
            <div className="flexRow">
              <div>{`3. Remark : `}</div>
              {/* <div className="selectedValue">{` ${selected_Remark}`}</div> */}
            </div>
          }
          // required
          placeholder="Other Relevant Descriptions"
          size="xl"
          // value={selected_Remark.current}
          onChange={(event) =>
            (selected_Remark.current = event.currentTarget.value)
          }
        />
      </>
    );
  };

  const COMP_SubmitButton = () => {
    return (
      <>
        <div className="container_center">
          <Button color="lime" size="xl" type="submit">
            Create New Ticket !
          </Button>
        </div>
      </>
    );
  };
  const COMP_Button = ({ p_selected_Category }) => {
    setSelected_Category(p_selected_Category);
    return (
      <>
        <div className="container_center">
          <br />
          <Button
            color="lime"
            size="xl"
            onClick={() => {
              console.log("COMP_Button", {
                selected_Outlet: selected_Outlet,
                selected_Category: selected_Category,
                selected__Option: selected__Option.current,
                selected_Option2:
                  selected__Option2a.current +
                  (selected__Option2b.current != ""
                    ? ` ,${selected__Option2b.current}`
                    : "") +
                  (selected__Option2c.current != ""
                    ? `, ${selected__Option2c.current}`
                    : "") +
                  (selected__Option2d.current != ""
                    ? `, ${selected__Option2d.current}`
                    : ""),
                selected_Remark: selected_Remark.current,
              });

              AddNewTicket_DailySubmit();
            }}
          >
            <div className="container_center   padding_1rem">
              <div style={{ fontSize: "2rem" }}>Create New Ticket</div>
              <div style={{ color: "red", fontSize: "1rem" }}>
                * This case, Need to submit separately
              </div>
            </div>
          </Button>
        </div>
      </>
    );
  };

  // --------------------------------------- func

  const reset_FromData_RemarkOnly = () => {
    selected_Remark.current = "";
  };
  const reset_FromData_OptionsOnly = () => {
    selected__Option.current = "";
    selected__Option2a.current = "";
    selected__Option2b.current = "";

    selected__Option2c.current = "";
  };
  const reset_FromData_CategoryAndOptionRemarkOnly = () => {
    setSelected_Category("");
    selected__Option.current = "";
    selected__Option2a.current = "";
    selected__Option2b.current = "";
    selected__Option2c.current = "";
    selected_Remark.current = "";
  };

  const removeDuplicate = (value, index, self) => {
    // ie. use case
    // thisArray.filter(removeDuplicate)
    return self.indexOf(value) === index;
  };

  const AddNewTicket_DailySubmit = () => {
    let final_Status = "pending";

    if (
      selected__Option.current.includes("All Good") ||
      selected__Option2a.current.includes("All Good")
    ) {
      final_Status = "solved";
    } else {
      // final_Status = "solved";
    }

    if (
      selected__Option.current.includes("FnB") &&
      selected__Option2a.current > 100 && //Can Drinks  input number ≤100
      selected__Option2b.current > 30 && //Snack input number ≤ 30
      selected__Option2c.current > 10 && //Instant Noodles input number ≤ 10
      selected__Option2d.current > 50 //Mineral Water input number ≤ 50
    ) {
      final_Status = "solved";
    } else {
      // final_Status = "solved";
    }

    if (
      selected_Outlet != "" &&
      selected_Category != "" &&
      selected__Option.current != ""
    ) {
      addDB_NewTicket(
        selected_Outlet,
        selected_Category,
        selected__Option.current,
        selected__Option2a.current +
          (selected__Option2b.current != ""
            ? ` ,${selected__Option2b.current}`
            : "") +
          (selected__Option2c.current != ""
            ? `, ${selected__Option2c.current}`
            : "") +
          (selected__Option2d.current != ""
            ? `, ${selected__Option2d.current}`
            : ""),
        // selected__Option2a + selected__Option2b + selected__Option2c,
        selected_Remark.current,
        selected_UUID,
        final_Status,

        contextValue?.firebase_UserInfo?.displayName,
        contextValue?.firebase_UserInfo?.uid,
        contextValue?.firebase_UserInfo?.photoURL
      );
      reset_FromData_OptionsOnly();
      reset_FromData_RemarkOnly();
      // reset_FromData_CategoryAndOptionRemarkOnly();
    } else {
      alert("Form Not Complete");
    }
  };
  // --------------------------------------- useEffect

  useEffect(() => {
    getDB_OutletInfo();
    getDB_GameNameList();
    getDB_RequestNameList();
    // console.log("Page_createTicket", contextValue);
    // console.log("parrent_DB_userinfo", parrent_DB_userinfo);
    setSelected_UUID(uuidv4().replaceAll("-", ""));
  }, []);

  // --------------------------------------- useFrom
  const form = useForm({});

  return (
    <>
      <COM_NavBar_Global />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="padding_2rem">
        <div className="normalContainerBG">
          <h1>Daily Submition</h1>
          <br />
          <Button
            color="grape"
            size="xl"
            onClick={() => {
              setDialogOpened(true);
              setTotalSubmitList([]);
            }}
          >
            <h3>Start Daily Submition</h3>
          </Button>
        </div>
        <br />
        <br />
        <div className="normalContainerBG">
          <h1>Create Ticket </h1>
          <br />
          <br />
          <form
            onSubmit={form.onSubmit(() => {
              let final_Status = "pending";

              if (
                selected__Option.current.includes("All Good") ||
                selected__Option2a.current.includes("All Good")
              ) {
                final_Status = "solved";
              } else {
                // final_Status = "solved";
              }

              if (
                selected__Option.current.includes("FnB") &&
                selected__Option2a.current > 100 && //Can Drinks  input number ≤100
                selected__Option2b.current > 30 && //Snack input number ≤ 30
                selected__Option2c.current > 10 && //Instant Noodles input number ≤ 10
                selected__Option2d.current > 50 //Mineral Water input number ≤ 50
              ) {
                final_Status = "solved";
              } else {
                // final_Status = "solved";
              }

              // console.log("selected__Option.current", selected__Option.current);
              // console.log(
              //   "selected__Option2a.current",
              //   selected__Option2a.current
              // );
              // console.log(
              //   "selected__Option2b.current",
              //   selected__Option2b.current
              // );
              // console.log(
              //   "selected__Option2c.current",
              //   selected__Option2c.current
              // );
              // console.log(
              //   "selected__Option2d.current",
              //   selected__Option.current
              // );

              if (
                selected_Outlet != "" &&
                selected_Category != "" &&
                selected__Option != ""
              ) {
                addDB_NewTicket(
                  selected_Outlet,
                  selected_Category,
                  selected__Option.current,
                  selected__Option2a.current +
                    (selected__Option2b.current != ""
                      ? ` ,${selected__Option2b.current}`
                      : "") +
                    (selected__Option2c.current != ""
                      ? `, ${selected__Option2c.current}`
                      : "") +
                    (selected__Option2d.current != ""
                      ? `, ${selected__Option2d.current}`
                      : ""),
                  // selected__Option2a + selected__Option2b + selected__Option2c,
                  selected_Remark.current,
                  selected_UUID,

                  final_Status,
                  contextValue?.firebase_UserInfo?.displayName,
                  contextValue?.firebase_UserInfo?.uid,
                  contextValue?.firebase_UserInfo?.photoURL
                );

                reset_FromData_CategoryAndOptionRemarkOnly();
              } else {
                alert("Form Not Complete");
              }
            })}
          >
            {/* ----------------------------------------------- 1. outlet */}
            <COMP_Outlet
              selected_Outlet={selected_Outlet}
              setSelected_Outlet={setSelected_Outlet}
            />
            <br />
            <br />
            <br />
            {/* ----------------------------------------------- 2. Category  */}

            <COMP_Category />

            <br />
            <br />
            <br />

            {/* ----------------------------------------------- 2. Option - Internet */}
            {selected_Category == "Internet" ? (
              <div className="subLv1">
                <COMP_Internet />
              </div>
            ) : (
              <div />
            )}
            {/* ----------------------------------------------- 2. Option - Internet */}
            {selected_Category == "WiFiServer" ? (
              <div className="subLv1">
                <COMP_WiFiServer />
              </div>
            ) : (
              <div />
            )}

            {/* ----------------------------------------------- 2. Option - Movie Server */}
            {selected_Category == "MovieServer" ? (
              <div className="subLv1">
                <COMP_MovieServer />
              </div>
            ) : (
              <div />
            )}

            {/* ----------------------------------------------- 2. Option - SaveGameServer Server */}
            {selected_Category == "SaveGameServer" ? (
              <div className="subLv1">
                <COMP_SaveGameServer />
              </div>
            ) : (
              <div />
            )}

            {/* ----------------------------------------------- 2. Option - Game */}
            {selected_Category == "Game" ? (
              <div className="subLv1">
                <COMP_Game />
              </div>
            ) : (
              <div />
            )}

            {/* ----------------------------------------------- 2. Option - EQHW */}
            {selected_Category == "EQHW" ? (
              <div className="subLv1">
                <COMP_EQHW p_selected__Option="showAll" />
              </div>
            ) : (
              <div />
            )}
            {/* ----------------------------------------------- 2. Option - AirCond */}
            {selected_Category == "AirCond" ? (
              <div className="subLv1">
                <COMP_AirCond p_selected__Option="showAll" />
              </div>
            ) : (
              <div />
            )}

            {/* ----------------------------------------------- 2. Option - Light */}
            {selected_Category == "Light" ? (
              <div className="subLv1">
                <COMP_Light />
              </div>
            ) : (
              <div />
            )}
            {/* ----------------------------------------------- 2. Option - Stock */}
            {selected_Category == "Stock" ? (
              <div className="subLv1">
                <COMP_Stock />
              </div>
            ) : (
              <div />
            )}
            {/* ----------------------------------------------- 2. Option - Request */}
            {selected_Category == "Request" ? (
              <div className="subLv1">
                <COMP_Request />
              </div>
            ) : (
              <div />
            )}
            {/* ----------------------------------------------- 2. Option - Other */}
            {selected_Category == "Other" ? (
              <div className="subLv1">
                <COMP_Other />
              </div>
            ) : (
              <div />
            )}

            {/* ----------------------------------------------- 3.  Remark */}
            <COMP_Remark />

            <br />
            <br />
            <br />
            {/* ----------------------------------------------- 4.  Attatchment */}
            {/* <div>{`file name postfix : ${selected_UUID}`}</div>
          <div className="flexRow gap">
            <COM_FileUpload
              uploadAllow={true}
              maxSizeMB="0.5"
              maxWidthOrHeight="1024"
              buttonName_PostFix={"PHOTO 1 ( Jpg Only ) "}
              serverDirPath={"uploads/AwesomeList/ticket_attachment"}
              uploadFileName={selected_UUID + "__1"}
              displayImgHeight="200px"
              fileTpe="jpg"
            />
            <COM_FileUpload
              uploadAllow={true}
              maxSizeMB="0.5"
              maxWidthOrHeight="1024"
              buttonName_PostFix={"PHOTO 2 ( Jpg Only ) "}
              serverDirPath={"uploads/AwesomeList/ticket_attachment"}
              uploadFileName={selected_UUID + "__2"}
              displayImgHeight="200px"
              fileTpe="jpg"
            />
            <COM_FileUpload
              uploadAllow={true}
              maxSizeMB="0.5"
              maxWidthOrHeight="1024"
              buttonName_PostFix={"PHOTO 3 ( Jpg Only ) "}
              serverDirPath={"uploads/AwesomeList/ticket_attachment"}
              uploadFileName={selected_UUID + "__3"}
              displayImgHeight="200px"
              fileTpe="jpg"
            />
            <COM_FileUpload
              uploadAllow={true}
              maxSizeMB="0.5"
              maxWidthOrHeight="1024"
              buttonName_PostFix={"PHOTO 4 ( Jpg Only ) "}
              serverDirPath={"uploads/AwesomeList/ticket_attachment"}
              uploadFileName={selected_UUID + "__4"}
              displayImgHeight="200px"
              fileTpe="jpg"
            />
          </div> */}
            <br />
            <br />
            {/* <div className="flexRow gap">
            <COM_FileUpload
              uploadAllow={true}
              maxSizeMB="10.5"
              maxWidthOrHeight="1024"
              buttonName_PostFix={"Video 1 ( Mp4 Only ) "}
              serverDirPath={"uploads/AwesomeList/ticket_attachment"}
              uploadFileName={selected_UUID + "__1"}
              displayImgHeight="200px"
              fileTpe="mp4"
            />
            <COM_FileUpload
              uploadAllow={true}
              maxSizeMB="10.5"
              maxWidthOrHeight="1024"
              buttonName_PostFix={"Video 2 ( Mp4 Only ) "}
              serverDirPath={"uploads/AwesomeList/ticket_attachment"}
              uploadFileName={selected_UUID + "__2"}
              displayImgHeight="200px"
              fileTpe="mp4"
            />
          </div> */}
            <COMP_SubmitButton />
            <br />
            <br />
            <br />
            <br />
          </form>
        </div>
      </div>
      <Modal
        opened={dialogOpened}
        onClose={() => setDialogOpened(false)}
        title={
          <h1>
            Daily Submission (
            {`${[...new Set(totalSubmitList)]?.length}/${
              EQHW_Options1?.length + AirCond_Options1?.length + 2 + 9 + 1
            } =  ${(
              ([...new Set(totalSubmitList)]?.length /
                (EQHW_Options1?.length +
                  AirCond_Options1?.length +
                  2 +
                  9 +
                  1)) *
              100
            ).toFixed(2)} % Complete`}
            )
          </h1>
        }
        centered
        size="full"
        transition="slide-down"
        transitionDuration={600}
        transitionTimingFunction="ease"
        overflow="inside"
      >
        <>
          <COMP_Outlet
            selected_Outlet={selected_Outlet}
            setSelected_Outlet={setSelected_Outlet}
          />
          <hr />

          <br />
          <br />
          <br />
          <div className="container_center glo_FontSize_2rem glo_FontWeight_600">
            Internet
          </div>
          <hr />
          {/* ---------------------------------------------- 1. Internet */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  1. Internet
                </div>
                <div style={{ color: "red" }}>Compulsory Submit</div>
              </>
            }
            size="xl"
            required
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Internet"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
              disabled
            />
            <Radio
              value="Report-Internet"
              label={<div className="btn_RB_Type1">Report-Internet</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Internet" ? (
            <div className="subLv1">
              <COMP_Internet />
              <COMP_Remark />

              <COMP_Button p_selected_Category="Internet" />
            </div>
          ) : (
            ""
          )}

          {/* ---------------------------------------------- 2. WiFi Server */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  2. WiFi Server
                </div>
                <div style={{ color: "red" }}>Compulsory Submit</div>
              </>
            }
            size="xl"
            required
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "WiFiServer"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
              disabled
            />
            <Radio
              value="Report-WiFiServer"
              label={<div className="btn_RB_Type1">Report-WiFi Server</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-WiFiServer" ? (
            <div className="subLv1">
              <COMP_WiFiServer />
              <COMP_Remark />

              <COMP_Button p_selected_Category="WiFiServer" />
            </div>
          ) : (
            ""
          )}

          <br />
          <br />
          <br />
          <div className="container_center glo_FontSize_2rem glo_FontWeight_600">
            Game
          </div>
          <div className="container_center glo_FontSize_1rem">
            Check Hot Game
          </div>

          <hr />

          {/* ---------------------------------------------- 1. Game */}

          {/* ---------------------------------------------- 1. Dota 2 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  1. Dota 2
                </div>
              </>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setSelected_Category("Game"); // preset
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Game-Data2"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Game-Data2"
              label={<div className="btn_RB_Type1">Report-Game</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Game-Data2" ? (
            <div className="subLv1">
              <COMP_Game
                p_selected__Option2="Data2"
                p_selected__Option2_Disabled={true}
              />
              <COMP_Remark />

              <COMP_Button p_selected_Category="Game" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 2. Fortnite */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  2. Fortnite
                </div>
              </>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setSelected_Category("Game"); // preset
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Game-Fortnite"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Game-Fortnite"
              label={<div className="btn_RB_Type1">Report-Game</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Game-Fortnite" ? (
            <div className="subLv1">
              <COMP_Game
                p_selected__Option2="Fortnite"
                p_selected__Option2_Disabled={true}
              />
              <COMP_Remark />

              <COMP_Button p_selected_Category="Game" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 3. PUBG */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  3. PUBG
                </div>
              </>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setSelected_Category("Game"); // preset
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Game-PUBG"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Game-PUBG"
              label={<div className="btn_RB_Type1">Report-Game</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Game-PUBG" ? (
            <div className="subLv1">
              <COMP_Game
                p_selected__Option2="PUBG"
                p_selected__Option2_Disabled={true}
              />
              <COMP_Remark />

              <COMP_Button p_selected_Category="Game" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 4. Grembox */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  4. Grembox (LoginOnly,DontPlay)
                </div>
              </>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setSelected_Category("Game"); // preset
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Game-Grembox"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Game-Grembox"
              label={<div className="btn_RB_Type1">Report-Game</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Game-Grembox" ? (
            <div className="subLv1">
              <COMP_Game
                p_selected__Option2="Grembox"
                p_selected__Option2_Disabled={true}
              />

              <COMP_Remark />

              <COMP_Button p_selected_Category="Game" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 5. CSGO */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  5. CSGO
                </div>
              </>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setSelected_Category("Game"); // preset
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Game-CSGO"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Game-CSGO"
              label={<div className="btn_RB_Type1">Report-Game</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Game-CSGO" ? (
            <div className="subLv1">
              <COMP_Game
                p_selected__Option2="CSGO"
                p_selected__Option2_Disabled={true}
              />

              <COMP_Remark />

              <COMP_Button p_selected_Category="Game" />
            </div>
          ) : (
            ""
          )}

          {/* ---------------------------------------------- 6. PUBG-FAOIFA */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  6. PUBG Free Account/Own ID Free account (FAOIFA)
                </div>
              </>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setSelected_Category("Game"); // preset
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Game-PUBG-FAOIFA"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Game-PUBG-FAOIFA"
              label={<div className="btn_RB_Type1">Report-Game</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Game-PUBG-FAOIFA" ? (
            <div className="subLv1">
              <COMP_Game
                p_selected__Option2="PUBG-FAOIFA"
                p_selected__Option2_Disabled={true}
              />
              <COMP_Remark />

              <COMP_Button p_selected_Category="Game" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 7. Apex-Legends */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  7. Apex-Legends
                </div>
              </>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setSelected_Category("Game"); // preset
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Game-Apex-Legends"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Game-Apex-Legends"
              label={<div className="btn_RB_Type1">Report-Game</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Game-Apex-Legends" ? (
            <div className="subLv1">
              <COMP_Game
                p_selected__Option2="Apex-Legends"
                p_selected__Option2_Disabled={true}
              />

              <COMP_Remark />

              <COMP_Button p_selected_Category="Game" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 8. Valorant */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  8. Valorant
                </div>
              </>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setSelected_Category("Game"); // preset
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Game-Valorant"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Game-Valorant"
              label={<div className="btn_RB_Type1">Report-Game</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Game-Valorant" ? (
            <div className="subLv1">
              <COMP_Game
                p_selected__Option2="Valorant"
                p_selected__Option2_Disabled={true}
              />

              <COMP_Remark />

              <COMP_Button p_selected_Category="Game" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 9. COD-Warzone */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  9. COD-Warzone
                </div>
              </>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setSelected_Category("Game"); // preset
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Game-COD-Warzone"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Game-COD-Warzone"
              label={<div className="btn_RB_Type1">Report-Game</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Game-COD-Warzone" ? (
            <div className="subLv1">
              <COMP_Game
                p_selected__Option2="COD-Warzone"
                p_selected__Option2_Disabled={true}
              />

              <COMP_Remark />

              <COMP_Button p_selected_Category="Game" />
            </div>
          ) : (
            ""
          )}
          <br />
          <br />
          <br />
          <div className="container_center glo_FontSize_2rem glo_FontWeight_600">
            EQHW
          </div>
          <hr />
          {/* {console.table([...new Set(totalSubmitList)])} */}
          {/* ---------------------------------------------- 1. Keyboard */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                1. Keyboard
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Keyboard"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Keyboard"
              label={<div className="btn_RB_Type1">Report-Keyboard</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Keyboard" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Keyboard" />

              <COMP_Remark />

              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}

          {/* ---------------------------------------------- 2. Mouse */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                2. Mouse
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Mouse"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Mouse"
              label={<div className="btn_RB_Type1">Report-Mouse</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Mouse" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Mouse" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 3. Mouse Pad */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                3. Mouse Pad
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Mouse Pad"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Mouse Pad"
              label={<div className="btn_RB_Type1">Report-Mouse Pad</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Mouse Pad" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Mouse Pad" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 4. Headphone */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                4. Headphone
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Headphone"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Headphone"
              label={<div className="btn_RB_Type1">Report-Headphone</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Headphone" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Headphone" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 5. Monitor */}
          <RadioGroup
            // defaultValue={['react']}

            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                5. Monitor
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Monitor"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Monitor"
              label={<div className="btn_RB_Type1">Report-Monitor</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Monitor" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Monitor" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 6. CPU */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">6. CPU</div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "CPU"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-CPU"
              label={<div className="btn_RB_Type1">Report-CPU</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-CPU" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="CPU" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 7. Printer */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                7. Printer
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Printer"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Printer"
              label={<div className="btn_RB_Type1">Report-Printer</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Printer" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Printer" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 8. CCTV */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                8. CCTV
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "CCTV"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-CCTV"
              label={<div className="btn_RB_Type1">Report-CCTV</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-CCTV" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="CCTV" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 9. Fidge */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                9. Fidge
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Fidge"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Fidge"
              label={<div className="btn_RB_Type1">Report-Fidge</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Fidge" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Fidge" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 10. Table */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                10. Table
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Table"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Table"
              label={<div className="btn_RB_Type1">Report-Table</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Table" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Table" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 11. Chair */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                11. Chair
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Chair"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Chair"
              label={<div className="btn_RB_Type1">Report-Chair</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Chair" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Chair" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 12. Water Supply */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                12. Water Supply
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Water Supply"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Water Supply"
              label={<div className="btn_RB_Type1">Report-Water Supply</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Water Supply" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Water Supply" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 13. Electric Door */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                13. Electric Door
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Electric Door"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Electric Door"
              label={<div className="btn_RB_Type1">Report-Electric Door</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Electric Door" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Electric Door" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 14. Toilet */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                14. Toilet
              </div>
            }
            size="xl"
            required
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Toilet"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Toilet"
              label={<div className="btn_RB_Type1">Report-Toilet</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Toilet" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Toilet" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 15. Santitizer */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                15. Santitizer
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Santitizer"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-Santitizer"
              label={<div className="btn_RB_Type1">Report-Santitizer</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-Santitizer" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="Santitizer" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 16. EQHW Other */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                16. EQHW Other
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "EQHW Other"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-EQHW Other"
              label={<div className="btn_RB_Type1">Report-EQHW Other</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-EQHW Other" ? (
            <div className="subLv1">
              <COMP_EQHW p_selected__Option="EQHW Other" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="EQHW" />
            </div>
          ) : (
            ""
          )}
          <br />
          <br />
          <br />
          <div className="container_center glo_FontSize_2rem glo_FontWeight_600">
            Air Cond
          </div>
          <hr />
          {/* ---------------------------------------------- 1. AC 1 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                1. AC 1
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 1"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 1"
              label={<div className="btn_RB_Type1">Report-AC 1</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 1" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 1" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 2. AC 2 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                2. AC 2
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 2"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 2"
              label={<div className="btn_RB_Type1">Report-AC 2</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 2" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 2" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 3. AC 3 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                3. AC 3
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 3"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 3"
              label={<div className="btn_RB_Type1">Report-AC 3</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 3" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 3" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 4. AC 4 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                4. AC 4
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 4"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 4"
              label={<div className="btn_RB_Type1">Report-AC 4</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 4" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 4" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 5. AC 5 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                5. AC 5
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 5"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 5"
              label={<div className="btn_RB_Type1">Report-AC 5</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 5" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 5" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 6. AC 6 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                6. AC 6
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 6"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 6"
              label={<div className="btn_RB_Type1">Report-AC 6</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 6" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 6" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 7. AC 7 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                7. AC 7
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 7"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 7"
              label={<div className="btn_RB_Type1">Report-AC 7</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 7" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 7" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 8. AC 8 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                8. AC 8
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 8"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 8"
              label={<div className="btn_RB_Type1">Report-AC 8</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 8" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 8" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 9. AC 9 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                9. AC 9
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 9"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 9"
              label={<div className="btn_RB_Type1">Report-AC 9</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 9" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 9" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 10. AC 10 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                10. AC 10
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 10"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 10"
              label={<div className="btn_RB_Type1">Report-AC 10</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 10" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 10" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 11. AC 11 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                11. AC 11
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 11"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 11"
              label={<div className="btn_RB_Type1">Report-AC 11</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 11" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 11" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 12. AC 12 */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                12. AC 12
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC 12"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC 12"
              label={<div className="btn_RB_Type1">Report-AC 12</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC 12" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC 12" />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          {/* ---------------------------------------------- 13. AC Other */}
          <RadioGroup
            // defaultValue={['react']}
            label={
              <div className="glo_FontSize_2rem glo_FontWeight_600">
                13. AC Other
              </div>
            }
            size="xl"
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "AC Other"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
            />
            <Radio
              value="Report-AC Other"
              label={<div className="btn_RB_Type1">Report-AC Other</div>}
            />
          </RadioGroup>
          {RB_Value == "Report-AC Other" ? (
            <div className="subLv1">
              <COMP_AirCond p_selected__Option="AC Other" />
              <hr />
              <COMP_Remark />
              <COMP_Button p_selected_Category="AirCond" />
            </div>
          ) : (
            ""
          )}
          <br />
          <br />
          <br />
          <div className="container_center glo_FontSize_2rem glo_FontWeight_600">
            Stock
          </div>
          <hr />

          {/* ---------------------------------------------- 1. Stock */}

          <RadioGroup
            // defaultValue={['react']}
            label={
              <>
                <div className="glo_FontSize_2rem glo_FontWeight_600">
                  1. Stock
                </div>
                <div style={{ color: "red" }}>Compulsory Submit</div>
              </>
            }
            size="xl"
            required
            checked={RB_Value}
            onChange={(x) => {
              setRB_Value(x);
              setTotalSubmitList((pre) => [...pre, "Stock"]);
            }}
          >
            <Radio
              value="All Good"
              label={<div className="btn_RB_Type1">All Good</div>}
              disabled
            />
            <Radio
              value="Report-Stock"
              label={<div className="btn_RB_Type1">Report-Stock</div>}
            />
          </RadioGroup>

          {RB_Value == "Report-Stock" ? (
            <div className="subLv1">
              <COMP_Stock />
              <COMP_Remark />

              <COMP_Button p_selected_Category="Stock" />
            </div>
          ) : (
            ""
          )}
          <br />
          <br />
          <br />

          <hr />
          <br />
          <br />
          <br />
          {(
            ([...new Set(totalSubmitList)]?.length /
              (EQHW_Options1?.length + AirCond_Options1?.length + 2 + 9 + 1)) *
            100
          ).toFixed(2) == "100.00" ? (
            <Button
              size="xl"
              color="lime"
              fullWidth
              onClick={() => {
                if (selected_Outlet != "") {
                  addDB_NewTicket(
                    selected_Outlet,
                    "DailySubmission",
                    "",
                    "",
                    // selected__Option2a + selected__Option2b + selected__Option2c,
                    "",
                    selected_UUID,
                    "pending",

                    contextValue?.firebase_UserInfo?.displayName,
                    contextValue?.firebase_UserInfo?.uid,
                    contextValue?.firebase_UserInfo?.photoURL
                  );
                  reset_FromData_OptionsOnly();
                  reset_FromData_RemarkOnly();
                  // reset_FromData_CategoryAndOptionRemarkOnly();
                } else {
                  alert("Form Not Complete");
                }
              }}
            >
              <div style={{ padding: "5rem", fontSize: "2rem" }}>
                {`Completed Daily Submission `}
                {`${(
                  ([...new Set(totalSubmitList)]?.length /
                    (EQHW_Options1?.length +
                      AirCond_Options1?.length +
                      2 +
                      9 +
                      1)) *
                  100
                ).toFixed(2)}
                %`}
              </div>
            </Button>
          ) : (
            <Button disabled fullWidth size="xl">
              <div style={{ padding: "5rem", fontSize: "2rem" }}>
                {`Completed Daily Submission`}
                {(
                  ([...new Set(totalSubmitList)]?.length /
                    (EQHW_Options1?.length +
                      AirCond_Options1?.length +
                      2 +
                      9 +
                      1)) *
                  100
                ).toFixed(2)}
                %
              </div>
            </Button>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <div>Tips : Tab+Space</div>
          {/* <h1>Part 1/2</h1>
          <hr />
          <h1>1</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Keyboard" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Keyboard" />
          </div>
          <h1>2</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Mouse" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Mouse" />
          </div>
          <h1>3</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Mouse Pad" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Mouse Pad" />
          </div>
          <h1>4</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Headphone" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Headphone" />
          </div>
          <h1>5</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Monitor" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Monitor" />
          </div>
          <h1>6</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="CPU" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="CPU" />
          </div>
          <h1>7</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Printer" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Printer" />
          </div>
          <h1>8</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="CCTV" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="CCTV" />
          </div>
          <h1>9</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Fidge" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Fidge" />
          </div>
          <h1>10</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Table" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Table" />
          </div>
          <h1>11</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Chair" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Chair" />
          </div>
          <h1>12</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Water Supply" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Water Supply" />
          </div>
          <h1>13</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Electric Door" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Electric Door" />
          </div>
          <h1>14</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Toilet" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Toilet" />
          </div>
          <h1>15</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Santitizer" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Santitizer" />
          </div>
          <h1>16</h1>
          <div className="subLv1">
            <COMP_EQHW p_selected__Option="Other" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Other" />
          </div>
          <hr />
          <h1>Part 2/2</h1>
          <hr />
          <h1>1</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 1" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 1" />
          </div>
          <h1>2</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 2" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 2" />
          </div>
          <h1>3</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 3" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 3" />
          </div>
          <h1>4</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 4" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 4" />
          </div>
          <h1>5</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 5" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 5" />
          </div>
          <h1>6</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 6" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 6" />
          </div>
          <h1>7</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 7" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 7" />
          </div>
          <h1>8</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 8" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 8" />
          </div>
          <h1>9</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 9" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 9" />
          </div>
          <h1>10</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 10" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 10" />
          </div>
          <h1>11</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 11" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 11" />
          </div>
          <h1>12</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="AC 12" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="AC 12" />
          </div>
          <h1>13</h1>
          <div className="subLv1">
            <COMP_AirCond p_selected__Option="Other" />
            <COMP_Remark />
            <COMP_Button p_selected__Option="Other" />
          </div> */}
        </>
      </Modal>
    </>
  );
}
