import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";

//  -------------------------- mantine
import {
  RadioGroup,
  Radio,
  Autocomplete,
  TextInput,
  Button,
  Modal,
  Group,
  Checkbox,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Refresh, FileSearch } from "tabler-icons-react";

//  -------------------------- Material Table
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // ------------------------------------------------- DB

  // all ticket record - pending and solving

  const [BD_Internet_Info, setBD_Internet_Info] = useState([]);
  const [BD_WiFiServer_Info, setBD_WiFiServer_Info] = useState([]);
  const [BD_MovieServer_Info, setBD_MovieServer_Info] = useState([]);
  const [BD_SaveGameServer_Info, setBD_SaveGameServer_Info] = useState([]);
  const [BD_Game_Info, setBD_Game_Info] = useState([]);
  const [BD_EQHW_Info, setBD_EQHW_Info] = useState([]);
  const [BD_AirCond_Info, setBD_AirCond_Info] = useState([]);
  const [BD_Light_Info, setBD_Light_Info] = useState([]);
  const [BD_Stock_Info, setBD_Stock_Info] = useState([]);
  const [BD_Other_Info, setBD_Other_Info] = useState([]);
  const get_DB_ticket_log_all__byOutletCatecoryStatus = (
    _solved,
    _t_outlet,
    _t_category,
    _ShowAllOutlet
  ) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__ticket_log__unsolveSummary__byOutletCatecoryStatus`,
        {
          solved: _solved,
          t_outlet: _t_outlet,
          t_category: _t_category,
          ShowAllOutlet: _ShowAllOutlet,
        }
      )
      .then((res) => {
        if (_t_category == "Internet") {
          setBD_Internet_Info(res.data);
          // console.log("Hit Internet", res.data);
        }
        if (_t_category == "WiFiServer") {
          setBD_WiFiServer_Info(res.data);
          // console.log("Hit Internet", res.data);
        }
        if (_t_category == "MovieServer") {
          setBD_MovieServer_Info(res.data);
          // console.log("Hit MovieServer", res.data);
        }
        if (_t_category == "SaveGameServer") {
          setBD_SaveGameServer_Info(res.data);
          // console.log("Hit SaveGameServer", res.data);
        }
        if (_t_category == "Game") {
          setBD_Game_Info(res.data);
          // console.log("Hit Game", res.data);
        }
        if (_t_category == "EQHW") {
          setBD_EQHW_Info(res.data);
          // console.log("Hit EQHW", res.data);
        }
        if (_t_category == "AirCond") {
          setBD_AirCond_Info(res.data);
          // console.log("Hit AirCond", res.data);
        }
        if (_t_category == "Light") {
          setBD_Light_Info(res.data);
          // console.log("Hit Light", res.data);
        }
        if (_t_category == "Stock") {
          setBD_Stock_Info(res.data);
          // console.log("Hit Stock", res.data);
        }
        if (_t_category == "Other") {
          setBD_Other_Info(res.data);
          // console.log("Hit Other", res.data);
        }
        // console.log(
        //   "# get_DB_ticket_log_all__byOutletCatecoryStatus",
        //   res.data
        // );
      });
  };

  // ------------------------------------------------- Table Columns
  const columns = [
    // {
    //   title: "Ticket Id",
    //   field: "t_id",
    //   width: "1%",
    //   cellStyle: {
    //     backgroundColor: "#2bc3ff",
    //     color: "#000",
    //     fontSize: "2rem",
    //   },
    //   headerStyle: {
    //     backgroundColor: "#2bc3ff",
    //     color: "#000",
    //     maxWidth: "1px",
    //   },
    // },
    {
      title: "Ticket ID",
      field: "t_id",
      width: "1px",
      cellStyle: {
        backgroundColor: "#2bc3ff",
        color: "#000",
        fontSize: "2rem",
      },
      headerStyle: {
        backgroundColor: "#2bc3ff",
        color: "#000",
        maxWidth: "1px",
      },
      render: (rowData) => (
        <>
          <div
            className="btn_TableEdit"
            // onClick={(e) => {
            //   setSelected_RowData(rowData);
            //   get_DB_ticket_log_all_byid(rowData.t_id);
            //   setDialogOpened(true);
            // }}
          >
            <div className="Action_profil_TicketNo ">
              <div>{`${rowData.t_id}`}</div>
            </div>

            <div className="container_center">
              <img className="Action_profilPic" src={rowData.gen_by_pic} />
            </div>
            <div className="Action_profil_Name">{rowData.gen_by}</div>
          </div>
          {/* <div className="container_center">
            <div
              className="btn_TableEdit"
              // onClick={(e) => {
              //   setSelected_RowData(rowData);
              //   get_DB_ticket_log_all_byid(rowData.t_id);
              //   setDialogOpened(true);
              // }}
            >
              <div className="flexRow_center">
                <FileSearch />
                <div>{`${rowData.t_id}`}</div>
              </div>

              <div className="flexRow_center">
                <img className="ico_profilPic" src={rowData.gen_by_pic} />
                <div className="text_fontzie_d5rem text_warp width_5rem">
                  {rowData.gen_by}
                </div>
              </div>
            </div>
          </div> */}
        </>
      ),
    },

    // {
    //   title: "Created by",
    //   render: (rowData) => (
    //     <div className="container_center">
    //       <img className="ico_profilPic" src={rowData.gen_by_pic} />
    //       {rowData.gen_by}
    //     </div>
    //   ),
    // },

    {
      title: "Status",
      field: "t_status",
      width: "1px",
      cellStyle: {
        // maxWidth: "1rem",
      },
      render: (rowData) => (
        <>
          <div className="container_center gap_dot2rem">
            <div
              className={
                rowData.t_status == "pending" ? "btn_Pending" : "btn_NULL"
              }
              onClick={() => {
                // set_DB_ticket_log__T_Status__byid("pending", rowData);
                alert('Plese settle the ticket at "My Tickets"');
              }}
            >
              <div className="container_center">
                <div className="glo_FontSize_1rem">PENDING</div>
                {rowData.t_status == "pending" ? (
                  <>
                    {/* <div className="text_Aging ">{`updated : ${rowData.aging_update}`}</div> */}
                    <div className="dis_container_inStatusbtn text_ColorBlack">
                      {/* <div className="flexRow">
                        <img
                          className="ico_profilPic_smallest"
                          src={rowData.status_by_pic}
                        />

                        <div className="container_center">
                          <div className=" ">{`${rowData.status_by}:`}</div>
                          <div className="glo_FontSize_1rem ">{`  ${rowData.t_status_remark}`}</div>
                        </div>
                      </div> */}
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </div>

            {/* <div
              className={
                rowData.t_status == "troubleshooting"
                  ? "btn_troubleshooting"
                  : "btn_NULL"
              }
              onClick={() => {
                set_DB_ticket_log__T_Status__byid("troubleshooting", rowData);
              }}
            >
              <div className="container_center">
                <div className="glo_FontSize_1rem">TROUBLESHOOTING</div>
                {rowData.t_status == "troubleshooting" ? (
                  <>
                    <div className="text_Aging ">{`updated : ${rowData.aging_update}`}</div>
                    <div className="dis_container_inStatusbtn text_ColorBlack">
                      <div className="flexRow">
                        <img
                          className="ico_profilPic_smallest"
                          src={rowData.status_by_pic}
                        />

                        <div className="container_center">
                          <div className=" ">{`${rowData.status_by}:`}</div>
                          <div className="glo_FontSize_1rem ">{`  ${rowData.t_status_remark}`}</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </div> */}

            <div
              className={
                rowData.t_status == "solved" ? "btn_solved" : "btn_NULL"
              }
              onClick={() => {
                // if (rowData.t_category == "DailySubmission") {
                //   if (contextValue?.bd_UserInfo?.u_role == "admin") {
                //     set_DB_ticket_log__T_Status__byid("solved", rowData);
                //   } else {
                //     alert("Staff , Not Allow");
                //   }
                // } else {
                //   set_DB_ticket_log__T_Status__byid("solved", rowData);
                // }
                alert('Plese settle the ticket at "My Tickets"');
              }}
            >
              <div className="container_center">
                <div className="glo_FontSize_1rem">SOLVED</div>
                {rowData.t_status == "solved" ? (
                  <>
                    {/* <div className="text_Aging ">{`updated : ${rowData.aging_update}`}</div> */}
                    <div className="dis_container_inStatusbtn text_ColorBlack">
                      {/* <div className="flexRow">
                        <img
                          className="ico_profilPic_smallest"
                          src={rowData.status_by_pic}
                        />

                        <div className="container_center">
                          <div className=" ">{`${rowData.status_by}:`}</div>
                          <div className="glo_FontSize_1rem ">{`  ${rowData.t_status_remark}`}</div>
                        </div>
                      </div> */}
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          {/* {rowData.t_status == "pending" ? (
            <div className="text_Pending">PENDING</div>
          ) : rowData.t_status == "troubleshooting" ? (
            <div className="text_Troubleshooting">TROUBLESHOOTING</div>
          ) : rowData.t_status == "solved" ? (
            <div className="text_Solved">SOLVED</div>
          ) : (
            <div>NA</div>
          )} */}
        </>
      ),
    },
    {
      title: "Aging / Create Date",
      field: "gen_dt",
      width: "1px",
      minWidth: "120px",
      render: (rowData) => (
        <>
          <div
            className={`${
              rowData.aging_create.split(",")[0] != "0day"
                ? "text_Aging_Remain_Day"
                : ""
            }`}
          >{`${
            rowData.aging_create.split(",")[0] != "0day"
              ? rowData.aging_create.split(",")[0]
              : ""
          }`}</div>

          <div
            className={`${
              rowData.aging_create.split(",")[1].trim() != "0h"
                ? "text_Aging_Remain_Hour"
                : ""
            }`}
            // className="text_Aging_Remain_Hour"
          >{` 
           ${
             rowData.aging_create.split(",")[1].trim() != "0h"
               ? rowData.aging_create.split(",")[1]
               : ""
           }`}</div>

          <div className="text_Aging_Remain_Min">{` 
           ${rowData.aging_create.split(",")[2]}`}</div>
          <br />
          <div>{`${rowData.gen_dt} `}</div>
        </>
      ),
    },

    {
      title: "Summary",
      field: "t_outlet",
      width: "1px",

      cellStyle: {
        minWidth: "33rem",
      },
      render: (rowData) => (
        <>
          <div className="text_fontzie_1rem">🎪{rowData.t_outlet}</div>

          <div
            className={`text_fontzie_1rem marginLeft_2rem ${
              rowData.t_category == "DailySubmission" ? "text_Gold" : ""
            }`}
          >
            🏷️{rowData.t_category}
          </div>

          <div className="text_fontzie_1rem marginLeft_4rem">
            🔍{rowData.t_option}
          </div>

          <div className="text_fontzie_1rem marginLeft_4rem">
            🔍
            {rowData.t_option == "FnB"
              ? `🧃${rowData.t_option2?.split(",")[0]}, 🥡 ${
                  rowData.t_option2?.split(",")[1]
                }, 🍜${rowData.t_option2?.split(",")[2]}, 🥛${
                  rowData.t_option2?.split(",")[3]
                }`
              : rowData.t_category == "Internet"
              ? `⏳${rowData.t_option2?.split(",")[0]}ms, ⤴️ ${
                  rowData.t_option2?.split(",")[1]
                } mbps, ⤵️${rowData.t_option2?.split(",")[2]} mbps
                `
              : rowData.t_option2}
          </div>

          <div className="text_fontzie_1rem marginLeft_4rem">
            📌{rowData.t_remark}
          </div>
        </>
      ),
    },

    {
      title: "Outlet",
      field: "t_outlet",
      width: "1px",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_outlet}</div>
      ),
    },
    {
      title: "Category",
      field: "t_category",
      width: "1px",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_category}</div>
      ),
    },
    {
      title: "Option",
      field: "t_option",
      width: "10%",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_option}</div>
      ),
    },
    {
      title: "Option2",
      field: "t_option2",
      width: "10%",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_option2}</div>
      ),
    },
    {
      title: "Remark",
      field: "t_remark",
      width: "50%",
      render: (rowData) => (
        <div className="glo_table_Font1">{rowData.t_option2}</div>
      ),
    },

    // {
    //   title: "Status Comment at",
    //   field: "status_dt",
    //   minWidth: "130px",
    // },

    // {
    //   title: "Comment by",
    //   render: (rowData) => (
    //     <div className="container_center">
    //       <img className="ico_profilPic" src={rowData.status_by_pic} />
    //       {rowData.status_by}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Last Comment",
    //   field: "t_status_remark",
    //   minWidth: "120px",
    // },
  ];

  const columns2 = [
    // {
    //   title: "Action",
    //   field: "ticket_status_desc",
    //   width: "1px",
    //   render: (rowData) => (
    //     <>
    //       <div className="container_center gap">
    //         <Button
    //           className="btn_TableEdit"
    //           aria-controls="simple-menu"
    //           aria-haspopup="true"
    //           // onClick={(e) => {
    //           // setSelected_RowData(rowData);
    //           // get_DB_ticket_log_all_byid(rowData.t_id);
    //           // setDialogOpened(true);
    //           // }}
    //         >
    //           <FileSearch />
    //         </Button>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "Created at",
      field: "gen_dt",
      minWidth: "120px",
      render: (rowData) => (
        <>
          <div className="text_Aging_Remain">{`${
            rowData.aging_create.split(",")[0]
          }, ${rowData.aging_create.split(",")[1]} ago`}</div>
          <br />
          <div>{`${rowData.gen_dt} `}</div>
        </>
      ),
    },
    {
      title: "Created by",
      render: (rowData) => (
        <div className="container_center">
          <img className="ico_profilPic" src={rowData.gen_by_pic} />
          {rowData.gen_by}
        </div>
      ),
    },
    {
      title: "Ticket Id",
      field: "t_id",
      width: "1%",
      cellStyle: {
        backgroundColor: "#2bc3ff",
        color: "#000",
      },
      headerStyle: {
        backgroundColor: "#2bc3ff",
        color: "#000",
        maxWidth: "1px",
      },
    },
    {
      title: "Status",
      field: "t_status",
      width: "1px",
      render: (rowData) => (
        <>
          <div className="container_center gap_dot2rem">
            <div
              className={
                rowData.t_status == "pending" ? "btn_Pending" : "btn_NULL"
              }
              // onClick={() => {
              //   set_DB_ticket_log__T_Status__byid("pending", rowData);
              // }}
            >
              <div className="container_center">
                <div className="text_fontzie_2rem">PENDING</div>
                {rowData.t_status == "pending" ? (
                  <>
                    <div className="text_Aging ">{`updated : ${rowData.aging_update}`}</div>
                    <div className="dis_container_inStatusbtn text_ColorBlack">
                      <div className="flexRow">
                        <img
                          className="ico_profilPic_smallest"
                          src={rowData.status_by_pic}
                        />

                        <div className="text_fontzie_2rem ">{`${rowData.status_by}: ${rowData.t_status_remark}`}</div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </div>

            <div
              className={
                rowData.t_status == "troubleshooting"
                  ? "btn_troubleshooting"
                  : "btn_NULL"
              }
              // onClick={() => {
              //   set_DB_ticket_log__T_Status__byid("troubleshooting", rowData);
              // }}
            >
              <div className="container_center">
                <div className="text_fontzie_20">TROUBLESHOOTING</div>
                {rowData.t_status == "troubleshooting" ? (
                  <>
                    <div className="text_Aging ">{`updated : ${rowData.aging_update}`}</div>
                    <div className="dis_container_inStatusbtn text_ColorBlack">
                      <div className="flexRow">
                        <img
                          className="ico_profilPic_smallest"
                          src={rowData.status_by_pic}
                        />

                        <div className="text_fontzie_2rem ">{`${rowData.status_by}: ${rowData.t_status_remark}`}</div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </div>

            <div
              className={
                rowData.t_status == "solved" ? "btn_solved" : "btn_NULL"
              }
              // onClick={() => {
              //   set_DB_ticket_log__T_Status__byid("solved", rowData);
              // }}
            >
              <div className="container_center">
                <div className="text_fontzie_2rem">SOLVED</div>
                {rowData.t_status == "solved" ? (
                  <>
                    <div className="text_Aging ">{`updated : ${rowData.aging_update}`}</div>
                    <div className="dis_container_inStatusbtn text_ColorBlack">
                      <div className="flexRow">
                        <img
                          className="ico_profilPic_smallest"
                          src={rowData.status_by_pic}
                        />

                        <div className="text_fontzie_2rem ">{`${rowData.status_by}: ${rowData.t_status_remark}`}</div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          {/* {rowData.t_status == "pending" ? (
            <div className="text_Pending">PENDING</div>
          ) : rowData.t_status == "troubleshooting" ? (
            <div className="text_Troubleshooting">TROUBLESHOOTING</div>
          ) : rowData.t_status == "solved" ? (
            <div className="text_Solved">SOLVED</div>
          ) : (
            <div>NA</div>
          )} */}
        </>
      ),
    },
    {
      title: "Outlet",
      field: "t_outlet",
      width: "1px",
      cellStyle: {
        fontSize: "2rem",
      },
    },
    {
      title: "Category",
      field: "t_category",
      width: "1px",
      cellStyle: {
        fontSize: "2rem",
      },
    },
    {
      title: "Option",
      field: "t_option",
      width: "10%",
      cellStyle: {
        fontSize: "2rem",
      },
    },
    {
      title: "Option2",
      field: "t_option2",
      width: "10%",
      cellStyle: {
        fontSize: "2rem",
      },
    },
    {
      title: "Remark",
      field: "t_remark",
      width: "50%",
      cellStyle: {
        fontSize: "2rem",
      },
    },

    // {
    //   title: "Status Comment at",
    //   field: "status_dt",
    //   minWidth: "130px",
    // },

    // {
    //   title: "Comment by",
    //   render: (rowData) => (
    //     <div className="container_center">
    //       <img className="ico_profilPic" src={rowData.status_by_pic} />
    //       {rowData.status_by}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Last Comment",
    //   field: "t_status_remark",
    //   minWidth: "120px",
    // },
  ];

  const ticket_Categories = [
    "ShowAll",
    "Internet",
    "WiFiServer",
    "MovieServer",
    "SaveGameServer",
    "Game",
    "EQHW",
    "AirCond",
    "Light",
    "Stock",
    "Other",
  ];

  const MaterialTable_Custom = (_TableTitle, _TableData) => {
    return (
      <MaterialTable
        style={{
          MuiTablePaginationRoot: "gold",
          color: "white",
          // background: "rgba(0, 0, 100, .0)",
          backgroundImage: "linear-gradient(to bottom right, #ffd900, #ffa90a)",
          boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
          backdropFilter: "blur( 11.0px )",
          "&::webkit-backdrop-filter": {
            webkitBackdropFilter: "blur( 18.0px )",
          },
        }}
        title={
          <div className="flexRow">
            <div className="text_TableTitle">{`${_TableTitle} (${_TableData?.length})`}</div>
          </div>
        }
        columns={columns}
        data={_TableData}
        options={{
          headerStyle: {
            background: "rgba(255, 255, 255, .2)",
            border: "none",
          },
          rowStyle: (rowData) => ({
            backgroundColor: "#1a0000",
            color: "#ffffff",
            fontSize: 12,
          }),
          pageSize: pageSize.current, //3,
          pageSizeOptions: [5, 10, 20, 50, 100],
          paginationType: "stepped",
        }}
      />
    );
  };

  // --------------------------------------- use context
  const contextValue = useContext(UserContext);

  // --------------------------------------- dialog / model

  const [dialogOpened, setDialogOpened] = useState(false);

  // --------------------------------------- global variable
  // const [pageSize, setPageSize] = useState("3");
  const pageSize = useRef("3");

  const [selected_RowData, setSelected_RowData] = useState([]);
  const selected_Outlet = useRef("");

  const [selected_Category, setSelected_Category] = useState("ShowAll");

  const getAllInfo = (_solved, _t_outlet, _t_category, _ShowAllOutlet) => {
    get_DB_ticket_log_all__byOutletCatecoryStatus(
      _solved,
      _t_outlet,
      "Internet",
      _ShowAllOutlet
    );
    get_DB_ticket_log_all__byOutletCatecoryStatus(
      _solved,
      _t_outlet,
      "WiFiServer",
      _ShowAllOutlet
    );
    get_DB_ticket_log_all__byOutletCatecoryStatus(
      _solved,
      _t_outlet,
      "MovieServer",
      _ShowAllOutlet
    );
    get_DB_ticket_log_all__byOutletCatecoryStatus(
      _solved,
      _t_outlet,
      "SaveGameServer",
      _ShowAllOutlet
    );
    get_DB_ticket_log_all__byOutletCatecoryStatus(
      _solved,
      _t_outlet,
      "Game",
      _ShowAllOutlet
    );
    get_DB_ticket_log_all__byOutletCatecoryStatus(
      _solved,
      _t_outlet,
      "EQHW",
      _ShowAllOutlet
    );
    get_DB_ticket_log_all__byOutletCatecoryStatus(
      _solved,
      _t_outlet,
      "AirCond",
      _ShowAllOutlet
    );
    get_DB_ticket_log_all__byOutletCatecoryStatus(
      _solved,
      _t_outlet,
      "Light",
      _ShowAllOutlet
    );
    get_DB_ticket_log_all__byOutletCatecoryStatus(
      _solved,
      _t_outlet,
      "Stock",
      _ShowAllOutlet
    );
    get_DB_ticket_log_all__byOutletCatecoryStatus(
      _solved,
      _t_outlet,
      "Other",
      _ShowAllOutlet
    );
  };

  useEffect(() => {
    getAllInfo();
  }, []);

  return (
    <>
      <COM_NavBar_Global />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* ----------------------------------------------- 1. outlet */}
      <div className="  margin_1rem">
        <h1>Summary</h1>
      </div>
      <br />
      <br />
      <br />
      <div className="normalContainerBG margin_1rem">
        <h1>Show Single Outlet</h1>
        <Autocomplete
          // value={selected_Outlet.current}
          onChange={(e) => {
            selected_Outlet.current = e;
          }}
          limit={100}
          required
          size="xl"
          label={
            <div className="flexRow">
              <div>{`Outlet : `}</div>
              {/* <div className="selectedValue">{` ${selected_Outlet}`}</div> */}
            </div>
          }
          placeholder=""
          // data={["React", "Angular", "Svelte", "Vue"]}
          // data={outletsInfo.map((x) => x.outlet_fullname)}

          data={contextValue?.bd_UserInfo?.u_outlets?.toString()?.split(",")}
        />

        <br />
        <Button
          color="lime"
          size="xl"
          onClick={() => {
            setSelected_Category("ShowAll");
            // setPageSize("3");
            pageSize.current = "3";
            getAllInfo("false", selected_Outlet.current, "Game", "false");
          }}
        >
          <FileSearch />{" "}
          {`SHOW OUTLET (${
            BD_Internet_Info?.length +
            BD_WiFiServer_Info?.length +
            BD_MovieServer_Info?.length +
            BD_SaveGameServer_Info?.length +
            BD_Game_Info?.length +
            BD_EQHW_Info?.length +
            BD_AirCond_Info?.length +
            BD_Light_Info?.length +
            BD_Stock_Info?.length +
            BD_Other_Info?.length
          })`}
        </Button>
        <br />
        <br />
      </div>
      <br />
      <div className="normalContainerBG margin_1rem">
        <h1>Show All Outlets</h1>

        <br />
        <RadioGroup
          value={selected_Category}
          onChange={(e) => {
            // setPageSize("100");
            pageSize.current = "100";
            setSelected_Category(e);
            // reset_FromData_OptionsOnly();
          }}
          label={
            <div className="flexRow">
              <div>{`Category : `}</div>
              <div className="selectedValue">{` ${selected_Category}`}</div>
            </div>
          }
          description=""
          size="xl"
          color="yellow"
          required
        >
          {ticket_Categories.map((x) => (
            <Radio
              value={x}
              label={
                <>
                  <div className=" glo_RadioBtn">
                    <div>{x}</div>

                    {x == "Internet" && BD_Internet_Info?.length > 0 ? (
                      <div className="icon1">{BD_Internet_Info?.length}</div>
                    ) : (
                      ""
                    )}

                    {x == "WiFiServer" && BD_WiFiServer_Info?.length > 0 ? (
                      <div className="icon1">{BD_WiFiServer_Info?.length}</div>
                    ) : (
                      ""
                    )}
                    {x == "MovieServer" && BD_MovieServer_Info?.length > 0 ? (
                      <div className="icon1">{BD_MovieServer_Info?.length}</div>
                    ) : (
                      ""
                    )}
                    {x == "SaveGameServer" &&
                    BD_SaveGameServer_Info?.length > 0 ? (
                      <div className="icon1">
                        {BD_SaveGameServer_Info?.length}
                      </div>
                    ) : (
                      ""
                    )}
                    {x == "Game" && BD_Game_Info?.length > 0 ? (
                      <div className="icon1">{BD_Game_Info?.length}</div>
                    ) : (
                      ""
                    )}
                    {x == "EQHW" && BD_EQHW_Info?.length > 0 ? (
                      <div className="icon1">{BD_EQHW_Info?.length}</div>
                    ) : (
                      ""
                    )}
                    {x == "AirCond" && BD_AirCond_Info?.length > 0 ? (
                      <div className="icon1">{BD_AirCond_Info?.length}</div>
                    ) : (
                      ""
                    )}
                    {x == "Light" && BD_Light_Info?.length > 0 ? (
                      <div className="icon1">{BD_Light_Info?.length}</div>
                    ) : (
                      ""
                    )}
                    {x == "Stock" && BD_Stock_Info?.length > 0 ? (
                      <div className="icon1">{BD_Stock_Info?.length}</div>
                    ) : (
                      ""
                    )}
                    {x == "Other" && BD_Other_Info?.length > 0 ? (
                      <div className="icon1">{BD_Other_Info?.length}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              }
            />
          ))}
        </RadioGroup>
        <br />

        <Button
          color="lime"
          size="xl"
          onClick={() => {
            // setPageSize("100");
            pageSize.current = "100";
            getAllInfo("false", "*", "Game", "true");
          }}
        >
          <FileSearch />{" "}
          {`SHOW ALL OUTLETS (${
            BD_Internet_Info?.length +
            BD_MovieServer_Info?.length +
            BD_SaveGameServer_Info?.length +
            BD_Game_Info?.length +
            BD_EQHW_Info?.length +
            BD_AirCond_Info?.length +
            BD_Light_Info?.length +
            BD_Stock_Info?.length +
            BD_Other_Info?.length
          })`}
        </Button>
      </div>
      <br />
      <br />
      <br />
      {selected_Category == "ShowAll" || selected_Category == "Internet"
        ? MaterialTable_Custom("Internet Status ", BD_Internet_Info)
        : ""}
      {selected_Category == "ShowAll" || selected_Category == "WiFiServer"
        ? MaterialTable_Custom("WiFiServer Status ", BD_WiFiServer_Info)
        : ""}
      {selected_Category == "ShowAll" || selected_Category == "MovieServer"
        ? MaterialTable_Custom("MovieServer Status ", BD_MovieServer_Info)
        : ""}
      {selected_Category == "ShowAll" || selected_Category == "SaveGameServer"
        ? MaterialTable_Custom("SaveGameServer Status ", BD_SaveGameServer_Info)
        : ""}
      {selected_Category == "ShowAll" || selected_Category == "Game"
        ? MaterialTable_Custom("Game Status ", BD_Game_Info)
        : ""}
      {selected_Category == "ShowAll" || selected_Category == "EQHW"
        ? MaterialTable_Custom("EQHW Status ", BD_EQHW_Info)
        : ""}
      {selected_Category == "ShowAll" || selected_Category == "AirCond"
        ? MaterialTable_Custom("AirCond Status ", BD_AirCond_Info)
        : ""}
      {selected_Category == "ShowAll" || selected_Category == "Light"
        ? MaterialTable_Custom("Light Status ", BD_Light_Info)
        : ""}
      {selected_Category == "ShowAll" || selected_Category == "Stock"
        ? MaterialTable_Custom("Stock Status ", BD_Stock_Info)
        : ""}
      {selected_Category == "ShowAll" || selected_Category == "Other"
        ? MaterialTable_Custom("Other Status ", BD_Other_Info)
        : ""}
    </>
  );
}
